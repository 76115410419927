import { Component, OnInit, ElementRef } from '@angular/core';
import { VariablesService } from './../common/services/variables.service';
import { surveysService } from './../common/services/surveys.service';
import { opportunitiesService } from './../common/services/opportunities.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from 'ngx-cookie-service';
import { FormControlName } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-admin.html',
  styleUrls: ['./dashboard.component.css']

})
export class DashboardAdminComponent implements OnInit {

  constructor(private service: VariablesService, private surservice: surveysService, private oppservice: opportunitiesService, private router: Router, private elRef: ElementRef, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private cookieService: CookieService) { }
  token: any;
  createtopicForm: FormGroup;
  submitted = false;
  postslikes: any[] = [];
  userName: any;
  firstName: any;
  lastName: any;
  surveyList: any[] = [];
  OppList: any[] = [];
  noRecords: boolean = false;
  noOppRecords: boolean = false;
  surveysCount: number;
  profilepic: any;
  facebookid: any;
  userlogo: any;
  targetpoints: any;
  totalpoints: any;
  pendingpoints: any;
  rewardpercentage: any;
  topicsData: any[] = [];
  categData: any[] = [];
  topicResponce: any[] = [];
  isTopics: boolean = true;
  topicTitle: any;
  topicDetails: any;
  topicId: any;
  txtReplyDesc: any;
  txtTopicTitle: any;
  txtTopicBody: any;
  ddlCatId: any;
  suggestedtopics: any;
  topiclike: any;
  resplike: any;
  topicdata: any;
  topicresplength: any;
  lstpstname: any;
  lstpostedat: any;
  topreplies: any;
  topviews: any;
  topusers: any;
  toplikes: any;
  topicuser1: any;
  topicuser2: any;
  topicusers: any;
  pageNo: any = 1;
  TopicReplyValidation: boolean = false;
  TopicReplyLengthValidation: boolean = false;
  pageTab: any = "categories";
  isLatestBtn: boolean = true;
  catgtype: string;
  selcatgid: string;
  showcat: boolean = true;
  catgtopicsdata: any[] = [];
  topicCount: any;
  postCount: any;
  likesReceived: any;
  searchOpp: any;
  dwidth: any = (window.screen.width);
  get f() {
    return this.createtopicForm.controls;
  }
  ngOnInit() {
    this.catgtype = "Latest";
    this.getUserData();
  //  this.getSurveys();
    this.getRewardPoints();
    this.getPostLikeCount();
  //  this.GetTopOpportunities(0);
    this.getlatesttopics();
    this.getUserRewardSurveysCount();
    this.createtopicForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(15)]],
      message: ['', [Validators.required, Validators.minLength(20)]],
      category: ['', Validators.required]
    });
    //if (performance.navigation.type == 2) {
    this.reloadtoDash();
    //}
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    // stop here if form is invalid
    if (this.createtopicForm.invalid) {
      this.spinner.hide();
      return;
    }
    let dealrsp: dashOpp = new dashOpp();
    dealrsp.topictitle = this.txtTopicTitle;
    dealrsp.topicbody = this.txtTopicBody;
    dealrsp.catId = this.ddlCatId;
    this.topicsData = [];
    this.service.createnewtopic(dealrsp)
      .subscribe((res: any) => {
        $('#staticBackdrop').modal('hide');
        this.getlatesttopics();
      });
  }
  getUserData() {
    this.service.getUserDetails()
      .subscribe((result: any) => {
        this.firstName = result.firstName;
        this.lastName = result.lastName;
        this.userlogo = result.facebook_user_logo;
        this.facebookid = result.facebookId;

        if (this.userlogo == "" || this.userlogo == null) {
          this.profilepic = "assets/icon-svg/profile.png"
        }
        else if (this.facebookid == null) {
          this.profilepic = "profileuploads/" + this.userlogo;
        }
        else { this.profilepic = this.userlogo; }
      })
  };
  //getSurveys() {
  //  this.surservice.GetSurveysList()
  //    .subscribe((result: any) => {
  //      if (result) {
  //        this.noRecords = false;
  //        this.surveyList = result;
  //        //this.surveysCount = this.surveyList.length;
  //      }
  //      else {
  //        this.noRecords = true;
  //        //this.surveysCount = 0;
  //      }
  //    });
  //}
  getUserRewardSurveysCount() {
    this.surservice.GetUserRewardSurveys()
      .subscribe((result: any) => {
        if (result) {
          this.surveysCount = result;
        }
        else {
          this.surveysCount = 0;
        }
      });
  }
  getRewardPoints() {
    this.surservice.GetRewardPointsList()
      .subscribe((result: any) => {
        if (result) {
          this.targetpoints = result.targetPoints;
          this.totalpoints = result.totalPoints;
          this.pendingpoints = result.pendingPoints;
          this.rewardpercentage = result.percentage;
        }
        else {
          this.targetpoints = 0;
          this.totalpoints = 0;
          this.pendingpoints = 0;
          this.rewardpercentage = 0;
        }
        $('#progress_bar').css('width', this.rewardpercentage + "%");
        $('#progress_bar1').css('width', this.rewardpercentage + "%");
      });
  }
  //GetTopOpportunities(catgid) {
  //  this.OppList = [];
  //  this.oppservice.GetTopOpportunities(catgid)
  //    .subscribe((result: any) => {
  //      if (result.length > 0) {
  //        this.noOppRecords = false;
  //        this.OppList = result;
  //      }
  //      else {
  //        this.noOppRecords = true;
  //      }
  //    });
  //}
  getPostLikeCount() {
    this.service.GetPostLikes()
      .subscribe((result: any) => {
        this.postslikes = result;
        this.topicCount = this.postslikes[0].topic_count;
        this.postCount = this.postslikes[0].post_count;
        this.likesReceived = this.postslikes[0].likes_received;
      });
  };
  newwindow(url) {
    //var w = window.open(url, '_blank', 'height=500,width=500');
    var iMyWidth;
    var iMyHeight;
    //half the screen width minus half the new window width (plus 5 pixel borders).
    iMyWidth = (window.screen.width / 2) - (400);
    //half the screen height minus half the new window height (plus title and status bars).
    iMyHeight = (window.screen.height / 2) - (225);
    //Open the window.
    var win2 = window.open(url, "_blank", "status=no,height=450,width=800,resizable=yes,left=" + iMyWidth + ",top=" + iMyHeight + ",screenX=" + iMyWidth + ",screenY=" + iMyHeight + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    win2.focus();
  }
  resizeIframe() {
    var iframe = document.getElementById("iframe1");
    iframe.style.height = (iframe.ownerDocument.body.scrollHeight + 300 + 'px');
  }
  LernMore(opp) {
    let myObj = { oppCatId: opp.opportunityCatgid, oppDesc: opp.opportunityDesc, oppId: opp.opportunityId, oppRespdesc: opp.opportunityRespdesc, oppResId: opp.opportunityRespid, oppTitle: opp.opportunityTitle, oppTotalOppDesc: opp.totalOpportunityDesc, pageMode: "DashBoard" };
    localStorage.setItem("oppObject", JSON.stringify(myObj));
    window.location.href = '/opportunities';
  }
  getCatgTopics(catgid, catgname) {
    this.catgtopicsdata = [];
    this.selcatgid = catgid;
    this.catgtype = catgname;
    this.service.getcategorytopics(catgid)
      .subscribe((result: any) => {
        this.catgtopicsdata = result.topic_list.topics;
      });
    window.scroll(0, 500);
    this.showcat = false;
  }
  getlatesttopics() {
    this.spinner.show();
    this.service.getlatesttopics()
      .subscribe((result: any) => {
        this.topicsData = result.topic_list.topics;
        this.catgtopicsdata = result.topic_list.topics;
        this.service.getcategorieslist()
          .subscribe((res: any) => {
            this.categData = res;
            this.topicsData.forEach(e => {
              this.categData.forEach(opt => {
                if (e.category_id == opt.id) {
                  e.category_name = opt.name;
                  e.background_colour = '#' + opt.color;
                  e.text_color = '#' + opt.text_color;
                }
              })
            });
            if (this.pageTab == "Latest") {
              this.highlightedLatestTab();
            }
            else {
              this.highlightedCatgTab();
            }
            this.spinner.hide();
            this.spinner.hide();
            this.spinner.hide();

          });
      });
    this.showcat = true;
  }
  getResponses(id, title, pagename) {
    this.pageTab = pagename;
    this.topicId = id;
    this.isTopics = false;
    this.topicDetails = '';
    this.topicTitle = '';
    this.topicResponce = [];
    //this.spinner.show();
    this.service.getsingletopicpostsbyid(id)
      .subscribe((res: any) => {
        this.topicdata = res;
        this.topreplies = res.posts_count - 1;
        this.topviews = res.views;
        this.topusers = res.participant_count;
        this.toplikes = res.like_count;
        this.topicusers = res.details.participants;
        //this.topicuser1 = res.details.participants[0].username;
        //this.topicuser2 = res.details.participants[1].username;
        this.topicDetails = res.post_stream.posts.filter(e => e.post_number == 1)[0];
        this.topicTitle = title;
        this.topiclike = res.post_stream.posts[0].actions_summary[0].count;
        this.topicResponce = res.post_stream.posts.filter(e => e.post_number != 1);
        this.topicresplength = this.topicResponce.length;
        this.lstpstname = res.details.last_poster.username;
        this.lstpostedat = res.last_posted_at;
        //this.resplike = res.post_stream.posts.filter(e => e.post_number != 1)[0].actions_summary[0].count;
        this.suggestedtopics = res.suggested_topics;
        this.spinner.hide();
        this.spinner.hide();
        window.scroll(0, 500)
        // this.spinner.hide();
      });
  }
  bactoTopic() {
    this.catgtype = "Latest";
    this.topicId = '';
    this.topicDetails = '';
    this.topicTitle = '';
    this.topicResponce = [];
    this.txtReplyDesc = '';
    this.TopicReplyValidation = false;
    this.TopicReplyLengthValidation = false;
    this.topicsData = [];
    this.categData = [];
    this.isTopics = true;
    this.isLatestBtn = true;
    this.pageNo = 1;
    this.getlatesttopics();

  }
  onTopicReply() {
    this.spinner.show();
    this.TopicReplyValidation = false;
    this.TopicReplyLengthValidation = false;
    if (!this.txtReplyDesc) {
      this.TopicReplyValidation = true;
      this.spinner.hide();
    }
    else {
      if (this.txtReplyDesc.length > 20) {
        this.sendreply();
      }
      else {
        this.TopicReplyLengthValidation = true;
        this.spinner.hide();
      }
    }
  }

  sendreply() {
    let dealrsp: dashOpp = new dashOpp();
    dealrsp.topicId = this.topicId;
    dealrsp.txtReplyDesc = this.txtReplyDesc;
    this.service.topicreply(dealrsp)
      .subscribe((res: any) => {
        this.txtReplyDesc = '';
        this.getResponses(this.topicId, this.topicTitle, this.pageTab);
      });
  }

  //CreatePost() {
  //  let dealrsp: dashOpp = new dashOpp();
  //  dealrsp.topictitle = this.txtTopicTitle;
  //  dealrsp.topicbody = this.txtTopicBody;
  //  dealrsp.catId = this.ddlCatId;
  //  this.topicsData = [];
  //  this.service.createnewtopic(dealrsp)
  //    .subscribe((res: any) => {
  //      $('#staticBackdrop').modal('hide');
  //      this.getlatesttopics();
  //    });

  //}

  createnewtopic() {
    this.txtTopicTitle = '';
    this.txtTopicBody = '';
    this.ddlCatId = '';
  }


  MoreLatest() {
    this.highlightedLatestTab();
    window.scroll(0, 500);
  }
  brouseCatg() {
    this.isTopics = true;
    this.highlightedCatgTab()
    window.scroll(0, 500);
  }


  postlikes(id) {
    this.spinner.show();
    this.service.likepost(id).subscribe((res: any) => {
      if (res == true) {
        // this.topiclike = this.topiclike + 1;
        this.getResponses(this.topicId, this.topicTitle, this.pageTab);
      }
      else {
        this.spinner.hide();
      }
    });

  }
  highlightedCatgTab() {
    $('#pills-home-tab').addClass('active');
    $('#pills-home-tab').attr('aria-selected', true);
    $('#pills-home').addClass('active');
    $('#pills-profile-tab').removeClass('active');
    $('#pills-profile-tab').attr('aria-selected', false);
    $('#pills-profile').removeClass('active');
    $('#pills-home').addClass('show');
    $('#pills-profile').removeClass('show');
  }
  highlightedLatestTab() {
    $('#pills-home-tab').removeClass('active');
    $('#pills-home-tab').attr('aria-selected', false);
    $('#pills-home').removeClass('active');
    $('#pills-profile-tab').addClass('active');
    $('#pills-profile-tab').attr('aria-selected', true);
    $('#pills-profile').addClass('active');
    $('#pills-home').removeClass('show');
    $('#pills-profile').addClass('show');
  }
  LatestMoreClick() {
    this.spinner.show();
    this.service.getlatestpagetopics(this.pageNo)
      .subscribe((result: any) => {
        result.topic_list.topics.forEach(e => {
          this.topicsData.push(e);
        })

        this.topicsData.forEach(e => {
          this.categData.forEach(opt => {
            if (e.category_id == opt.id) {
              e.category_name = opt.name;
              e.background_colour = '#' + opt.color;
              e.text_color = '#' + opt.text_color;
            }
          })
        });
        if (result.topic_list.topics.length < 30) {
          this.isLatestBtn = false;
          this.spinner.hide();
        }
        else {
          this.isLatestBtn = true;
          this.pageNo = this.pageNo + 1;
          this.spinner.hide();
        }
      });
  }
  reloadtoDash() {
    this.service.sessionExist().subscribe((data) => {
      var token = this.cookieService.get('token');
      if (token && data == "at") {
      }
      else {
        window.location.href = '/login';
      }
    });
  }
}
export class dashOpp {
  topictitle: string;
  topicbody: string;
  catId: number;
  topicId: number;
  txtReplyDesc: string;
}
