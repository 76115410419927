import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIGURATION } from '../../app.constants';
import { CookieService } from 'ngx-cookie-service';
import { Resp, CreateOpp } from '../../opportunities/opportunities.component';
import { dashOpp } from '../../dashboard/dashboard.component';
@Injectable({
  providedIn: 'root'
})
export class opportunitiesService {
  url: any;
  constructor(private location: Location, private http: HttpClient, private cookieService: CookieService) {
    this.url = CONFIGURATION.baseUrls.ServerPath;
  }
  Getopportunities() {
    return this.http.get(this.url + 'Opportunities/GetOpportunities', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
          //,'X-XSRF-TOKEN': this.cookieService.get('XSRF-TOKEN')

        })
    });
  }
  
  GetJobOpportunities(keyword, Zipcode, jobType,distance,experience) {
    return this.http.get(this.url + 'Opportunities/GetJobOpportunities?keyword=' + keyword + '&location=' + Zipcode + '&jobType=' + jobType + '&distance=' + distance + '&experience=' + experience, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  GetTopOpportunities(catgid) {
    return this.http.get(this.url + 'Opportunities/GetTopOpportunities?catgid=' + catgid, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  Getopportunitiesres(id) {
    return this.http.get(this.url + 'Opportunities/GetOpportunityResponse?id=' + id, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  SaveopportunitiesResponse(body: Resp) {
    const url1 = this.url + 'Opportunities/SaveOpportunitysResponse';
    var data = { oppdesc: body.oppdesc, oppid: body.oppid, username: body.username, title: body.title }
    return this.http.post<Resp>(url1, data, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
          'content-type': 'application/json'
        })
    });
  }
  OpportunitiesSave(body: CreateOpp) {
    const url1 = this.url + 'Opportunities/OpportunitiesSave';
    var data = { cat_id: body.cat_id, title: body.title, desc: body.desc, location: body.location }
    return this.http.post<CreateOpp>(url1, data, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
          'content-type': 'application/json'
        })
    });
  }
  GetTopTopics() {
    return this.http.get(this.url + 'Discourse/gettoptopics', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  OpportunitiesDelete(oppId) {
    return this.http.get(this.url + 'Opportunities/OpportunitiesDelete?oppId=' + oppId, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }


  OpportunitiesResponseDelete(oppRespId) {
    return this.http.get(this.url + 'Opportunities/OpportunitiesResponseDelete?oppRespId=' + oppRespId, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  GetUserOpportunities() {
    return this.http.get(this.url + 'Opportunities/GetUserOpportunities', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  GetDiscUserTopics() {
    return this.http.get(this.url + 'Discourse/getusertopicsposts1', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  GetDiscUserActions(filter: any) {
    return this.http.get(this.url + 'Discourse/getuseractions?filter=' + filter, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }

  DelDiscTopics(topicid: any) {
    return this.http.get(this.url + 'Discourse/topicdelete?topicid=' + topicid, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  OpportunitiesUpdate(body: CreateOpp) {
    const url1 = this.url + 'Opportunities/OpportunitiesUpdate';
    var data = { title: body.title, desc: body.desc, oppr_id: body.oppr_id, location: body.location }
    return this.http.post<CreateOpp>(url1, data, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
          'content-type': 'application/json'
        })
    });
  }
  oppById(oppId) {
    const url1 = this.url + 'Opportunities/GetOpporById?id=' + oppId;
    return this.http.get(url1, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
          'content-type': 'application/json'
        })
    });
  }
  updateTopicTitle(body: dashOpp) {
    const url1 = this.url + 'Discourse/DiscEditTopicTitle';
    var data = { topicId: body.topicId, topictitle: body.topictitle, catId: body.catId }
    return this.http.post<dashOpp>(url1, data, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
          'content-type': 'application/json'
        })
    });
  }
  updateTopicRes(body: dashOpp) {
    const url1 = this.url + 'Discourse/DiscEditTopicRes';
    var data = { topicId: body.topicId, topicbody: body.topicbody }
    return this.http.post<dashOpp>(url1, data, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
          'content-type': 'application/json'
        })
    });
  }
  getUserRole() {
    return this.http.get(this.url + 'Opportunities/getuserRole', { responseType: 'text' });
  }
  getrouterurltrackingpixel(leadid, pixeltypeid) {
    return this.http.get(this.url + 'Opportunities/getRouterurltrackingpixel?leadid=' + leadid + '&ptid=' + pixeltypeid, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  getrouterurlpixels(leadid, pixeltypeid) {
    return this.http.get(this.url + 'Opportunities/getrouterurlpixels?leadid=' + leadid + '&ptid=' + pixeltypeid, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
}
