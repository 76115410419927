import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIGURATION } from '../../app.constants';
import { CookieService } from 'ngx-cookie-service';
import { InviteFriendsComponent, InviteFriends } from '../../invitefriends/invitefriends.component';

@Injectable({
  providedIn: 'root'
})
export class invitefriendsService {
  url: any;
  constructor(private location: Location, private http: HttpClient, private cookieService: CookieService) {
    this.url = CONFIGURATION.baseUrls.ServerPath;
  }
  Invitefriends(body,campId,TempSub) {
    const url1 = this.url + 'Invitefriends/Invitefriends?camID=' + campId + "&tid=" + TempSub;
    return this.http.post<InviteFriends>(url1, body, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
          'content-type': 'application/json'
        })
    });
  }
  validEmail(emailslist) {
    //return this.http.get(this.url + 'Invitefriends/vldEmail?email=' + body[0].FriendEmailAddress + '&email=' + body[1].FriendEmailAddress + '&email=' + body[2].FriendEmailAddress + '&email=' + body[3].FriendEmailAddress + '&email=' + body[4].FriendEmailAddress, { responseType: 'text' });
    return this.http.get(this.url + 'Invitefriends/vldEmail?email=' + emailslist, { responseType: 'text' });
  }
  emailAddressCheck(email) {
    return this.http.get(this.url + 'Invitefriends/EmailAddressCheck?email=' + email);
  }
  GetFriendInformation() {
    return this.http.get(this.url + 'Invitefriends/GetFriendInformation');
  }
}
