import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams, HttpClientModule } from '@angular/common/http';
import { CONFIGURATION } from '../../app.constants';
import { Observable } from 'rxjs';
import { reg, userdata, clickdata } from '../../registration/registration.component';
import { Member, UserSocial } from '../../login/login.component';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class registrationService {

  url: any;
  userName: any;
  chkemail: any;
  constructor(private location: Location, private http: HttpClient, private cookieService: CookieService) {
    this.url = CONFIGURATION.baseUrls.ServerPath;
  }

  RegistrationSave(body: reg) {
    const url1 = this.url + 'Registration/CreateWL';
    var data = {
      Address1: body.Address1,
      Address2: body.Address2,
      FirstName: body.FirstName,
      LastName: body.LastName,
      EmailAddress: body.EmailAddress,
      Gender: body.Gender,
      ZipCode: body.ZipCode,
      UserName: body.UserName,
      City: body.City,
      Password: body.Password,
      StateId: body.StateId,
      CountryId: body.CountryId,
      PhoneNumber: body.PhoneNumber,
      EthnicityId: body.EthnicityId,
      Dob: body.Dob,
      CountryCode: body.CountryCode,
      Employer: body.Employer,
      JobTitle: body.JobTitle,
      IsReceiveSms: body.IsReceiveSms,
      SubId3:body.SubId3,     
      SubId2: body.SubId2,
      CreatedBy: body.CreatedBy,     
      RefferId: body.RefferId
      
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<reg>(url1, data, { headers, responseType: 'text' as 'json' });
  }
  FBRegistration(member: Member) {
    const url1 = this.url + 'Registration/FBRegistration';
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<Member>(url1, member, { headers });
  }
  FaceBookRegistration(member: UserSocial) {
    const url1 = this.url + 'UserSocial/FacebookRegistration';
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<UserSocial>(url1, member, { headers });

  }

  GetCountrysAndStates() {
    return this.http.get(this.url + 'Registration/GetCountrysAndStates', {

    });
  }
  GetEthinicity() {
    return this.http.get(this.url + 'Registration/GetEthinicity', {

    });
  }

  GetUserProfile() {
    return this.http.get(this.url + 'login/GetUserProfile', {
    });
  }
  GetUserInfo(leadid) {
    return this.http.get(this.url + 'login/GetUserInfo?leadid=' + leadid, {
    });
  }
  GetEmailCheck(chkemail) {
    return this.http.get(this.url + 'Registration/EmailAddressCheck?email=' + chkemail, {
    });
  }
  GetUserCheck(chkUser) {
    return this.http.get(this.url + 'Registration/UserNameCheck?userName=' + chkUser, {
    });
  }
  GetCheckSubid(data: userdata) {
    return this.http.get(this.url + 'Registration/UserSubidCheck?emailid=' + data.EmailAddress + "&subid=" + data.SubId3, {
    });
  }
  DeleteAccount() {
    return this.http.get(this.url + 'Registration/DeleteUser', {
    });
  }
  ClikInsert(data: clickdata) {
    return this.http.get(this.url + 'Registration/InsertClicks?rid=' + data.rid + "&sid=" + data.sid + "&txid=" + data.txid + "&trans_id=" + data.trans_id, {
    });
  }
  UserUpdate(body: reg) {
    const url1 = this.url + 'Registration/UserUpdate';
    var data = {
      Address1: body.Address1,
      Address2: body.Address2,
      FirstName: body.FirstName,
      LastName: body.LastName,
      EmailAddress: body.EmailAddress,
      Gender: body.Gender,
      ZipCode: body.ZipCode,
      UserName: body.UserName,
      City: body.City,
      Password: body.Password,
      StateId: body.StateId,
      CountryId: body.CountryId,
      PhoneNumber: body.PhoneNumber,
      EthnicityId: body.EthnicityId,
      Dob: body.Dob,
      CountryCode: body.CountryCode,
      Employer: body.Employer,
      JobTitle: body.JobTitle

    }
    //const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<reg>(url1, data, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
          'content-type': 'application/json'
        })
    });
  }

  ProfileSave(uid, fblogo) {
    return this.http.get(this.url + 'Registration/ProfileSave?userid=' + uid + '&facebookuserlogo=' + fblogo, {
    });

  }
}
