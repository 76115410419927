import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardAdminComponent } from './dashboard/dashboard-admin';
import { CirclesComponent } from './circles/circles.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { OpportunitiesComponent } from './opportunities/opportunities.component';
import { VariablesService } from './common/services/variables.service';
import { seoservice } from './common/services/seo.service';
import { SurveysComponent } from './surveys/surveys.component';
import { RewardsComponent } from './rewards/rewards.component';
import { RegistrationComponent } from './registration/registration.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NeedsAuthentication } from './common/services/needs.authentication';
import { CookieService } from 'ngx-cookie-service';
import { SocialLoginModule, AuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { ContactusComponent } from './contactus/contactus.component';
import { NewsfeedComponent } from './newsfeed/newsfeed.component';
import { AboutrewardsComponent } from './aboutrewards/aboutrewards.component';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { DonotsellmydataComponent } from './donotsellmydata/donotsellmydata.component';
import { NgxPaginationModule } from 'ngx-pagination';
// search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AboutusComponent } from './aboutus/aboutus.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UserOpportunitiesComponent } from './opportunities/useropportunities.component';
import { ShortNamePipe } from '../Pipes/shortname.pipe';
import { TimeAgoPipe } from 'time-ago-pipe';
import { ReplacePipe } from './replace.pipe';
import { FaqComponent } from './faq/faq.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { InviteFriendsComponent } from './invitefriends/invitefriends.component';
//import { ForgotPasswordComponent } from './forgotpassword/forgotpassword';
//import { FilterPipe } from '../Pipes/filter.pipe';
//import { HtmlToPlaintextPipe } from './html-to-plaintext.pipe';
//import { ShortNamePipes } from './short-name.pipe';
import { SpinnerComponent } from './spinner.component';
import { Regstep1Component } from './regstep1/regstep1.component';
const config = new AuthServiceConfig([
  //{
  //  id: FacebookLoginProvider.PROVIDER_ID,
  //  provider: new FacebookLoginProvider('236095787420060')
  //}
]);
export function provideConfig() {
  return config;
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent,
    DashboardAdminComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    OpportunitiesComponent,
    SurveysComponent,
    CirclesComponent,
    RewardsComponent,
    RegistrationComponent,
    PrivacypolicyComponent,
    TermsAndConditionsComponent,
    CookiepolicyComponent,
    ContactusComponent,
    NewsfeedComponent,
    AboutrewardsComponent,
    DonotsellmydataComponent,
    AboutusComponent,
    UserOpportunitiesComponent,
    ShortNamePipe,
    TimeAgoPipe,
    ReplacePipe,
    FaqComponent,
    UnsubscribeComponent,
    SpinnerComponent,
    InviteFriendsComponent,
    Regstep1Component
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    SocialLoginModule,
    Ng2SearchPipeModule,
    NgxSpinnerModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'registration', component: RegistrationComponent },
      { path: 'circles', component: CirclesComponent },
      { path: 'circles/t/:title/:id', component: CirclesComponent },
      { path: 'circles/t/:title/:id/:tab', component: CirclesComponent },
      { path: 'opportunities', component: OpportunitiesComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'dashboardadmin', component: DashboardAdminComponent },
      { path: 'surveys', component: SurveysComponent },
      { path: 'rewards', component: RewardsComponent },
      { path: 'privacypolicy', component: PrivacypolicyComponent },
      { path: 'termsandconditions', component: TermsAndConditionsComponent },
      { path: 'cookiepolicy', component: CookiepolicyComponent },
      { path: 'contactus', component: ContactusComponent },
      { path: 'donotsellmydata', component: DonotsellmydataComponent },
      { path: 'newsfeed', component: NewsfeedComponent },
      { path: 'aboutus', component: AboutusComponent },
      { path: 'aboutrewards', component: AboutrewardsComponent },
      { path: 'useropportunities', component: UserOpportunitiesComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'dashboard/t/:title/:id', component: DashboardComponent },
      { path: 'dashboard/t/:title/:id/:tab', component: DashboardComponent },
      { path: 'dashboard/c/:catgtitle/:catgid', component: DashboardComponent },
      { path: 'unsubscribe/:id', component: UnsubscribeComponent },
      { path: 'unsubscribe/:id/:otrid', component: UnsubscribeComponent },
      { path: 'unsubscribe', component: UnsubscribeComponent },
      { path: 'invitefriends', component: InviteFriendsComponent },
      { path: 'regstep1', component: Regstep1Component },
      { path: '**', component: HomeComponent, pathMatch: 'full' }
    ])
  ],
  providers: [VariablesService, NeedsAuthentication, CookieService, HeaderComponent, seoservice,
    { provide: AuthServiceConfig, useFactory: provideConfig },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
