import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class seoservice {
  constructor(private title: Title, private metaTag: Meta,@Inject(DOCUMENT) private dom) { }

  //createCanonicalURL() {
  //  let link: HTMLLinkElement = this.dom.createElement('link');
  //  link.setAttribute('rel', 'canonical');
  //  this.dom.head.appendChild(link);
  //  link.setAttribute('href', this.dom.URL);
  //}
  createCanonicalURL(url?: string) {
    let canURL = url == undefined ? this.dom.URL : url;
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }
  setPageMetaData(title: string, description: string) {
    this.title.setTitle(title);
    this.metaTag.updateTag({ name: 'description', content: description }, "name='description'");
  }
  addFacebookMetaTag() {
    this.metaTag.addTag({ name: 'facebook-domain-verification', content: '4i2xtm39euitz3kdhp0kbec52njvlp' });
  }
  getPageTitle() {
    return this.title.getTitle();
  }
}
