import { Component, OnInit } from '@angular/core';
import { VariablesService } from '../common/services/variables.service';
import { CookieService } from 'ngx-cookie-service';
import { seoservice } from './../common/services/seo.service';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private service: VariablesService,private cookieService: CookieService, private SeoService: seoservice) { }
  cmail: any;
  userdata: any;
  googleCookie:boolean = true;
  cookie:number

  ngOnInit() {
    this.SeoService.setPageMetaData('Home | The most rewarding business community - Conversant.pro', 'Connect with peers, business opportunities and industry news while earning rewards for participation in the community and business surveys.');
    this.service.DiscourseLoginreqFalse().subscribe((result: any) => {
    });
    $('#carouselExampleCaptions').carousel({
      interval: 7000
    })
    $('#carouselExampleIndicators').carousel({
      interval: 7000
    })
    this.getSessionData();
    this.checkCookie();
  }

declineCookie(){
  this.cookieService.deleteAll();
  this.googleCookie = false;
}

checkCookie(){
  var user = this.cookieService.get('obcookie');
  this.cookie = parseInt(user);
  if(this.cookie == 1){
    this.googleCookie = false;
  }else{
    this.googleCookie = true;
  }
}


acceptCookiest() {
  var now = new Date();
  var time = now.getTime();
  var expireTime = time + (3600 * 1000) * 8766;
  now.setTime(expireTime);
  document.cookie = ('obcookie') + "=" + ('1') + ';expires=' + now.toUTCString() + ';path=/';
  this.cookieService.set('obcookie',document.cookie);
  this.googleCookie = false;
}

cookieSettings(){
  window.open('https://wl.conversant.pro/Login/cookiesettings','','width=800,height=800');
  this.googleCookie = false;
}


closeDialog(){
this.googleCookie = false;
}

  newwindow(url) {
    //var w = window.open(url, '_blank', 'height=500,width=500');
    var iMyWidth;
    var iMyHeight;
    //half the screen width minus half the new window width (plus 5 pixel borders).
    iMyWidth = (window.screen.width / 2) - (400);
    //half the screen height minus half the new window height (plus title and status bars).
    iMyHeight = (window.screen.height / 2) - (225);
    //Open the window.
    var win2 = window.open(url, "_blank", "status=no,height=500,width=800,resizable=yes,left=" + iMyWidth + ",top=" + iMyHeight + ",screenX=" + iMyWidth + ",screenY=" + iMyHeight + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    win2.focus();
  }
  getSessionData() {
    this.service.userSessionData()
      .subscribe((result: any) => {
        if (result != 0) {
          window.location.href = '/dashboard';
        }
        else {
          this.cmail = this.cookieService.get('cemail');
            //this.cmail = "srinivas@precisionsoftech.com";
          if (this.cmail != "") {
            this.service.getUserData(this.cmail).subscribe((data) => {
              this.userdata = data;
              if (this.userdata.emailAddress !="")
                window.location.href = '/dashboard';
            });

          }
        }


      });
  }
}
