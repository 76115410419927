import { Component, OnInit } from '@angular/core';
import { surveysService } from './../common/services/surveys.service';
import { Router, ActivatedRoute } from '@angular/router';
import { seoservice } from './../common/services/seo.service';
@Component({

  templateUrl: './surveys.component.html'

})
export class SurveysComponent implements OnInit {
  constructor(private service: surveysService, private router: Router, private activatedRoute: ActivatedRoute, private SeoService: seoservice) { }
  surveyList: any[] = [];
  noRecords: boolean = false;
  loginUserGuid: any;
  usg: string;
  cid: any;
  uguid: string;
  rewardamount: string;
  congrats: boolean = false;
  prescreenerterm: boolean = false;
  thankYou: boolean = false;
  psoqs: boolean = false;
  psoqthankYou: boolean = false;
  weAreSorry: boolean = false;
  QuotaFull: boolean = false;
  sorryQuota: boolean = false;
  youTerminated: boolean = false;
  alredyParticipated: boolean = false;
  thankParticipated: boolean = false;
  offersNotExists: boolean = false;
  QQQuotaFull: boolean = false;
  pixelscript: any = [];
  pixel_type: string = "";
  ngOnInit() {
    this.SeoService.setPageMetaData('Surveys | Get rewarded for sharing your opinions - Conversant.pro', 'Earn rewards by sharing your opinion and solving challenges in surveys tailored specifically to you and those in your industry.');
    //this.getSurveys();
    //this.getLoginUserData();

    this.activatedRoute.queryParams.subscribe(params => {
      this.usg = params['usg'];
      this.cid = params['cid'];
      this.uguid = params['ug'];
    });
    if (this.usg == undefined) {
      this.usg = '';
    }
    if (this.cid == undefined) {
      this.cid = '';
    }
    if (this.usg != "")
    {
      if (this.usg.toLowerCase() == "6ac169c6-df47-4cd1-8f4d-1311f5c5f163" || this.usg.toLowerCase() == "181cf682-614e-46ec-9716-816af9dfe43d" || this.usg.toLowerCase() == "167944ad-051f-48e2-b458-184a27c27ece") {
        this.congrats = true;
        this.getrouterurlpixel();
      }
      else if (this.usg.toLowerCase() == "f6a6b754-4cf8-41bb-b9aa-5b97c412b1f4" || this.usg.toLowerCase() == "2b9038b6-db53-429a-8854-7bb83338b2d4") {
        if (this.cid == 385) {
          this.prescreenerterm = true;
        }
        else {
          this.thankYou = true;
        }
      }
      else if (this.usg.toLowerCase() == "c1f0f26d-fe64-4a7f-a2a3-69379a0fea91") {
        if (this.cid == 38) {
          this.psoqs = true;
        }
        else {
          this.psoqthankYou = true;
        }
      }
      else if (this.usg.toLowerCase() == "ec9ad2bb-a92b-4781-87c1-5d3b505f6cd3") {
        this.weAreSorry = true;
      }
      else if (this.usg.toLowerCase() == "67b98bed-9c3f-42ae-bdd3-7e15f9c17f00") {
        if (this.cid == 38) {
          this.QuotaFull = true;
        }
        else {
          this.sorryQuota = true;
        }
      }
      else if (this.usg.toLowerCase() == "d5f04cf6-50ab-4617-9b0f-95b23a07488c" || this.usg.toLowerCase() == "b75a1590-2786-45f9-a5e3-656ae1c13724") {
        this.youTerminated = true;
      }
      else if (this.usg.toLowerCase() == "664b50cb-e1e7-40cc-b2eb-a94e1d54228f") {
        this.alredyParticipated = true;
      }
      else if (this.usg.toLowerCase() == "a24bc10d-1eeb-4a1a-83fd-8789180631ef") {
        this.thankParticipated = true;
      }
      else if (this.usg != "") {
        this.offersNotExists = true;
      }
    }

    this.getLoginUserData();
    this.getSurveys();

  }
  getSurveys() {
    this.service.GetSurveysList()
      .subscribe((result: any) => {
        if (result) {
          this.noRecords = false;
          this.surveyList = result;
        }
        else {
          this.noRecords = true;
        }
      });
  };
  getLoginUserData() {
    this.service.GetLoginUserData()
      .subscribe((result: any) => {
        if (result) {
          this.loginUserGuid = result.userGuid;
        }
      });
  };
  getUserInfo() {
    this.service.GetUserInfo(this.uguid)
      .subscribe((result: any) => {
        if (result) {
          this.loginUserGuid = result.userGuid;
        }
      });
  };
  getrouterurlpixel() {
    this.service.getrouterurltrackingpixel(this.loginUserGuid,3)
      .subscribe((result: any) => {
        this.pixelscript = result;
        this.pixel_type = this.pixelscript.pixel_type;
        if (this.pixel_type == 'J') {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.async = true;// it is true by default, but we are just applying it.
          if (this.pixelscript.src != '') {
            script.src = this.pixelscript.src;
          }
          else if (this.pixelscript.attributrValue != '') {
            script.innerHTML = this.pixelscript.attributrValue
          }
          document.body.appendChild(script);
        }
        else if (this.pixel_type == 'I') {
          var imgsfpixel = document.createElement('img');
          imgsfpixel.height = 1;
          imgsfpixel.width = 1;
          imgsfpixel.alt = "Ipixel";
          if (this.pixelscript.src != '') {
            //imgsfpixel.src = this.pixelscript.src;
            imgsfpixel.src = this.pixelscript.src.replace("%%client_cpi%%", this.loginUserGuid).replace("%%external_member_id%%", this.cid);
          }
          else if (this.pixelscript.attributrValue != '') {
            imgsfpixel.innerHTML = this.pixelscript.attributrValue
          }
          document.getElementsByTagName('head')[0].appendChild(imgsfpixel);
        }
      });
  };
  newwindow(url,rewardamt) {
    //var w = window.open(url, '_blank', 'height=500,width=500');
    this.rewardamount = rewardamt;
    var iMyWidth;
    var iMyHeight;
    //half the screen width minus half the new window width (plus 5 pixel borders).
    iMyWidth = (window.screen.width / 2) - (400);
    //half the screen height minus half the new window height (plus title and status bars).
    iMyHeight = (window.screen.height / 2) - (225);
    //Open the window.
    var win2 = window.open(url, "_blank", "status=no,height=450,width=800,resizable=yes,left=" + iMyWidth + ",top=" + iMyHeight + ",screenX=" + iMyWidth + ",screenY=" + iMyHeight + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    win2.focus();
    setTimeout(() => { this.getSurveys();}, 5000);
  }
  popupProfile(profileid) {
    //var w = window.open(url, '_blank', 'height=500,width=500');
    var pid = "";
    if (profileid == 1) {
      pid = "2E6B8AE2-32B6-45A3-A65B-FC748D397E51";
    }
    else if (profileid == 2) {
      pid = "8BB9309A-8692-4BCF-B3BE-EFEDD5D6F5D5";
    }
    else if (profileid == 3) {
      pid = "49FAA29F-3C4C-4E45-AA8F-7A5F79729F96";
    }
    var profileUrl = "https://wl.conversant.pro/mem/pf?pid=" + pid + "&ug=" + this.loginUserGuid + "&cid=-1";
    var iMyWidth;
    var iMyHeight;
    //half the screen width minus half the new window width (plus 5 pixel borders).
    iMyWidth = (window.screen.width / 2) - (400);
    //half the screen height minus half the new window height (plus title and status bars).
    iMyHeight = (window.screen.height / 2) - (300);
    //Open the window.
    var win2 = window.open(profileUrl, "_blank", "status=no,height=500,width=800,resizable=yes,left=" + iMyWidth + ",top=" + iMyHeight + ",screenX=" + iMyWidth + ",screenY=" + iMyHeight + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    win2.focus();
  }
  checkmenu() {
    if (this.loginUserGuid) {
      window.location.href = '/invitefriends';
    }
    else {
      window.location.href = '/login';
    }
  }
}
