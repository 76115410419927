import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIGURATION } from '../../app.constants';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class surveysService {
  url: any;
  constructor(private location: Location, private http: HttpClient, private cookieService: CookieService) {
    this.url = CONFIGURATION.baseUrls.ServerPath;
  }
  GetSurveysList() {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(this.url + 'Survey/GetSurveysList', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  GetUserRewardSurveys() {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(this.url + 'Reward/GetUserRewardSurveys', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }

  GetRewardPointsList() {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(this.url + 'Survey/GetRewardPoints', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }

  GetLoginUserData() {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(this.url + 'Survey/GetLoginUserData', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  GetUserInfo(uguid) {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(this.url + 'Survey/GetUserInfo?uguid=' + uguid, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  getrouterurltrackingpixel(leadid, pixeltypeid) {
    return this.http.get(this.url + 'Opportunities/getRouterurltrackingpixel?leadid=' + leadid + '&ptid=' + pixeltypeid, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }

}
