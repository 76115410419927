import { Component } from '@angular/core';
import { SpinnerService } from './common/services/spinner.service';


@Component({
  selector: 'spinner-component',
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
  public active: boolean = false;

  public constructor(spinner: SpinnerService) {

    spinner.status.subscribe((status: boolean) => {

      this.active = status;
    });
  }
}
