import { Component, OnInit } from '@angular/core';
import { opportunitiesService } from './../common/services/opportunities.service'
import { VariablesService } from '../common/services/variables.service';
import { surveysService } from './../common/services/surveys.service'
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { NgxPaginationModule } from 'ngx-pagination';
import { Subscription } from 'rxjs/Subscription';
import { NgxSpinnerService } from "ngx-spinner";
import { seoservice } from './../common/services/seo.service';
declare var $: any;
@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.css']
})
export class OpportunitiesComponent implements OnInit {
  constructor(private service: opportunitiesService, private surservice: surveysService, private router: Router, private formBuilder: FormBuilder, private cookieService: CookieService, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, private varservice: VariablesService, private SeoService: seoservice) { }
  username: any;
  token: any;
  OppList: any[] = [];
  JobList: any[] = [];
  SearchJobList: any[] = [];
  MentorsList: any[] = [];
  displayDialog: boolean = false;
  OppId: any;
  OppTitle: any;
  OppDesc: any;
  OppRespDesc: any;
  txtDealsResponse: any;
  txtTitle: string;
  txtMessage: string;
  catId: any;
  titleReq: boolean = false;
  messageReq: boolean = false;
  oppDialog: boolean = false;
  isDealsShow: boolean = true;
  isDealsResShow: boolean = false;
  isJobShow: boolean = true;
  isJobResShow: boolean = false;
  OppJobId: any;
  OppJobTitle: any;
  OppJobDesc: any;
  txtJobResponse: any;
  isMentorsShow: boolean = true;
  isMentorsResShow: boolean = false;
  OppMentorId: any;
  OppMentorTitle: any;
  OppMentorDesc: any;
  txtMentorResponse: any;
  oppRes: any[] = [];
  oppJobResList: any[] = [];
  oppMentorsResList: any[] = [];
  surveyList: any[] = [];
  modalForm1: FormGroup;
  submitted = false;
  Opportunities: string;
  DealsValidationRes: boolean = false;
  DealsSavedSuccess: boolean = false;
  JobValidationRes: boolean = false;
  JobsSavedSuccess: boolean = false;
  MentorValidationRes: boolean = false;
  MentorsSavedSuccess: boolean = false;
  noRecords: boolean = false;
  noResponses: boolean = true;
  topicList: any[] = [];
  dashOppObj: any;
  footerOppObj: any;
  searchOpp: any;
  searchJob: any;
  searchMen: any;
  delRespId: any;
  jobRespId: any;
  menRespId: any;
  dlsOppUpdtBy: any;
  //dlsUserId: any;
  jobOppUpdtBy: any;
  //jobUserId: any;
  mentOppUpdtBy: any;
  isUserExist: boolean = false;
  searchtext: string;
  //mentUserId: any;
  noDealsRecords: boolean = true;
  noJobsRecords: boolean = true;
  noMentorsRecords: boolean = true;
  isOppIdExist: boolean = true;
  oppId: any;
  delType: any;
  UserOppList: any[] = [];
  noUserRecords: boolean = true;
  editesoppId: any;
  noToptopicsRec: boolean = false;
  adminUser: boolean = false;
  txtLocation: any;
  txtLocaReq: boolean = false;
  txtUpdLocation: any;
  txtUpdLocaReq: any;
  Keyword: any;
  Zipcode: any;
  selectedJob: any = 1;
  seldistance: any = 0;
  selexperience: any = 3;
  txtResponse: any;
  p4: any = 1;
  ngOnInit() {
    this.SeoService.setPageMetaData('Opportunities | Connect and grow your business - Conversant.pro', 'Earn rewards by engaging with business opportunities such as new deals, job openings, and mentorships with your peers.');
    //var token = this.cookieService.get('token')
    this.varservice.userSessionData().subscribe((result: any) => {
      if (result != 0) {
        this.isUserExist = true;
      }
    });
    //if (token) {
    //  this.isUserExist = true;
    //}
    //else {
    //  this.isUserExist = false;
    //}
    this.modalForm1 = this.formBuilder.group({
      title: ['', [Validators.required]],
      message: ['', Validators.required],

    });
    if (localStorage.getItem("oppObject")) {
      this.dashOppObj = JSON.parse(localStorage.getItem("oppObject"));
      if (this.dashOppObj.pageMode == "DashBoard") {
        this.GetopportunitiesLearn();
      }
      else {
        this.Getopportunities();
      }
    }
    else if (localStorage.getItem("footerObject")) {
      this.footerOppObj = JSON.parse(localStorage.getItem("footerObject"));
      this.GetopportunitiesFooter();
    }
    else {
      this.Getopportunities();
    }

    // this.getNewsfeed();
    if (this.isUserExist) {
      this.getSurveys();
      this.getTopTopics();
      this.getUseropportunities();
    }

    this.activatedRoute.queryParams.subscribe(params => {
      if (typeof params['filter'] != 'undefined') {
        this.searchtext = params['filter'];
      }
      if (typeof params['catgid'] != 'undefined') {
        this.catId = params['catgid'];
      }
    })
    this.getUserRole();
  }

  get f() {
    return this.modalForm1.controls;
  }
  KeywordSearch() {
    this.spinner.show();
    this.getJobOpportunities();
  }
  jobChange() {
    this.spinner.show();
    this.getJobOpportunities();
  }
  getJobOpportunities() {
    this.service.GetJobOpportunities(this.Keyword, this.Zipcode, this.selectedJob, this.seldistance, this.selexperience)
      .subscribe((result: any) => {
        this.JobList = result;
        if (this.JobList.length == 0) {
          this.noJobsRecords = true;
          this.spinner.hide();
        }
        else {
          this.noJobsRecords = false;
          this.spinner.hide();
        }

      });
  }
  Getopportunities() {
    this.OppList = [];
    this.JobList = [];
    this.MentorsList = [];
    this.service.Getopportunities()
      .subscribe((result: any) => {
        result.forEach(i => {
          if (i.opportunityCatgid == 1) {
            this.OppList.push(i);
            this.noDealsRecords = false;
          }
          else if (i.opportunityCatgid == 2) {
            this.JobList.push(i);
            this.noJobsRecords = false;
          }
          else if (i.opportunityCatgid == 3) {
            this.MentorsList.push(i);
            this.noMentorsRecords = false;
          }
        });
        this.SearchJobList = this.JobList;
      });
  }
  getUseropportunities() {
    this.UserOppList = [];
    this.service.GetUserOpportunities()
      .subscribe((result: any) => {
        this.UserOppList = result;
        if (this.UserOppList.length == 0) {
          this.noUserRecords = true;
        }
        else {
          this.noUserRecords = false;
        }
      });
  }
  GetopportunitiesLearn() {
    this.OppList = [];
    this.JobList = [];
    this.MentorsList = [];
    this.service.Getopportunities()
      .subscribe((result: any) => {
        result.forEach(i => {
          if (i.opportunityCatgid == 1) {
            this.OppList.push(i);
          }
          else if (i.opportunityCatgid == 2) {
            this.JobList.push(i);
          }
          else if (i.opportunityCatgid == 3) {
            this.MentorsList.push(i);
          }
        });

        this.DashLearn();
        localStorage.setItem("oppObject", "")
      });

  }
  GetopportunitiesFooter() {
    this.OppList = [];
    this.JobList = [];
    this.MentorsList = [];
    this.service.Getopportunities()
      .subscribe((result: any) => {
        result.forEach(i => {
          if (i.opportunityCatgid == 1) {
            this.OppList.push(i);
          }
          else if (i.opportunityCatgid == 2) {
            this.JobList.push(i);
          }
          else if (i.opportunityCatgid == 3) {
            this.MentorsList.push(i);
          }
        });
        this.FooterDash();
        localStorage.setItem("footerObject", "")
      });

  }
  GetopprResp(id) {
    this.service.Getopportunitiesres(id)
      .subscribe((result: any) => {
        if (result.length != 0) {
          if (result[0].opportunityCatgId == 1) {
            this.oppRes = [];
          }
          else if (result[0].opportunityCatgId == 2) {
            this.oppJobResList = [];
          }
          else if (result[0].opportunityCatgId == 3) {
            this.oppMentorsResList = [];
          }
          result.forEach(i => {

            if (i.opportunityCatgId == 1) {
              this.oppRes.push(i);
              this.noResponses = false;
            }
            if (i.opportunityCatgId == 2) {
              this.oppJobResList.push(i);
              this.noResponses = false;

            }
            if (i.opportunityCatgId == 3) {
              this.oppMentorsResList.push(i);
              this.noResponses = false;
            }
          });

        }

      });
  }
  LernMore(opp) {
    this.noResponses = true;
    if (opp.opportunityCatgid == 1) {
      $('#nav-contact-tab').removeClass('active');
      $('#nav-profile-tab').removeClass('active');
      $('#nav-home-tab').addClass('active');
      $('#nav-home-tab').attr('aria-selected', true);
      $('#nav-profile-tab').attr('aria-selected', false);
      $('#nav-contact-tab').attr('aria-selected', false);
      $('#nav-mentors').removeClass('active');
      $('#nav-jobs').removeClass('active');
      $('#nav-deals').addClass('active');
      this.oppRes = [];
      this.isDealsShow = false;
      this.isDealsResShow = true;
      this.OppId = opp.opportunityId;
      this.OppTitle = opp.opportunityTitle;
      this.OppDesc = opp.totalOpportunityDesc;
      this.dlsOppUpdtBy = opp.updatedBy;
      //this.dlsUserId = opp.userId;
      this.GetopprResp(this.OppId);
    }
    if (opp.opportunityCatgid == 2) {
      if (opp.islink == true) {
        var joblink = opp.opportunityLink;
        var paramArr = joblink.split(',');
        this.J2c_view(paramArr[0], paramArr[1], paramArr[2], paramArr[3], paramArr[4], paramArr[5], paramArr[6], paramArr[7], paramArr[8], paramArr[9], paramArr[10], paramArr[11], paramArr[12]);
      }
      else {
        $('#nav-contact-tab').removeClass('active');
        $('#nav-home-tab').removeClass('active');
        $('#nav-profile-tab').addClass('active');
        $('#nav-home-tab').attr('aria-selected', false);
        $('#nav-profile-tab').attr('aria-selected', true);
        $('#nav-contact-tab').attr('aria-selected', false);
        $('#nav-deals').removeClass('active');
        $('#nav-mentors').removeClass('active');
        $('#nav-jobs').addClass('active');
        this.oppJobResList = [];
        this.isJobShow = false;
        this.isJobResShow = true;
        this.OppJobId = opp.opportunityId;
        this.OppJobTitle = opp.opportunityTitle;
        this.OppJobDesc = opp.totalOpportunityDesc;
        this.jobOppUpdtBy = opp.updatedBy;
        //this.jobUserId = opp.userId;
        if (this.OppJobId == 0) {
          this.isOppIdExist = false;
        }
        else {
          this.isOppIdExist = true;
        }
        this.GetopprResp(this.OppJobId);
      }
    }
    if (opp.opportunityCatgid == 3) {
      $('#nav-home-tab').removeClass('active');
      $('#nav-profile-tab').removeClass('active');
      $('#nav-contact-tab').addClass('active');
      $('#nav-home-tab').attr('aria-selected', false);
      $('#nav-profile-tab').attr('aria-selected', false);
      $('#nav-contact-tab').attr('aria-selected', true);
      $('#nav-deals').removeClass('active');
      $('#nav-jobs').removeClass('active');
      $('#nav-mentors').addClass('active');
      this.oppMentorsResList = [];
      this.isMentorsShow = false;
      this.isMentorsResShow = true;
      this.OppMentorId = opp.opportunityId;
      this.OppMentorTitle = opp.opportunityTitle;
      this.OppMentorDesc = opp.totalOpportunityDesc;
      this.mentOppUpdtBy = opp.updatedBy;
      //this.mentUserId = opp.userId;
      this.GetopprResp(this.OppMentorId);
    }
    window.scroll(0, 100);
  }
  SaveDealsRes() {
    if (!this.txtDealsResponse) {
      this.DealsValidationRes = true;
      return;
    }

    let dealrsp: Resp = new Resp();
    dealrsp.title = this.OppTitle;
    dealrsp.oppdesc = this.txtDealsResponse;
    dealrsp.oppid = this.OppId;
    dealrsp.username = this.cookieService.get("userName")
    this.service.SaveopportunitiesResponse(dealrsp)
      .subscribe((result: any) => {
        this.txtDealsResponse = '';
        this.GetopprResp(this.OppId);
        this.DealsSavedSuccess = true;
      });
  }
  SaveJobRes() {
    if (!this.txtJobResponse) {
      this.JobValidationRes = true;
      return;
    }
    let jobrsp: Resp = new Resp();
    jobrsp.oppdesc = this.txtJobResponse;
    jobrsp.oppid = this.OppJobId;
    jobrsp.username = this.cookieService.get("userName")
    this.service.SaveopportunitiesResponse(jobrsp)
      .subscribe((result: any) => {
        this.txtJobResponse = '';
        this.GetopprResp(this.OppJobId);
        this.JobsSavedSuccess = true;
      });
  }
  SaveMentorsRes() {
    if (!this.txtMentorResponse) {
      this.MentorValidationRes = true;
      return;
    }
    let mentorrsp: Resp = new Resp();
    mentorrsp.oppdesc = this.txtMentorResponse;
    mentorrsp.oppid = this.OppMentorId;
    mentorrsp.username = this.cookieService.get("userName");
    this.service.SaveopportunitiesResponse(mentorrsp)
      .subscribe((result: any) => {
        this.txtMentorResponse = '';
        this.GetopprResp(this.OppMentorId);
        this.MentorsSavedSuccess = true;
      });
    //this.username = this.cookieService.get("userName")
    //let oresp: Resp = new Resp();
    //oresp.oppdesc = this.txtDealsResponse;
    //oresp.oppid = this.OppId;
    //oresp.username = this.username;
    //this.service.SaveopportunitiesResponse(oresp)
    //  .subscribe((result: any) => {
    //    this.txtMentorResponse = '';
    //    this.GetopprResp(this.OppMentorId);
    //  });
  }
  Create(Id) {
    this.catId = Id;
    if (this.catId == 1) {
      this.Opportunities = "Create Deal";
    }
    else if (this.catId == 2) {
      this.Opportunities = "Create Job";
    }
    else if (this.catId == 3) {
      this.Opportunities = "Create Mentor";
    }
    if (this)
      this.submitted = false;
    this.modalForm1.reset();
    this.txtLocation = '';

  }
  CreateOpp() {
    if (this.modalForm1.invalid) {
      this.submitted = true;
      return;
    }
    else {
      this.SaveOpp();
    }
  }
  SaveOpp() {
    this.txtLocaReq = false;
    let ocreateopp: CreateOpp = new CreateOpp();
    ocreateopp.cat_id = this.catId;
    ocreateopp.title = this.modalForm1.value.title;
    ocreateopp.desc = this.modalForm1.value.message;
    ocreateopp.location = this.txtLocation;
    if (this.catId == 2) {
      if (this.txtLocation) {
        this.Saveopportunities(ocreateopp);
      }
      else {
        this.txtLocaReq = true;
      }
    }
    else {
      this.Saveopportunities(ocreateopp);
    }


  }
  Saveopportunities(ocreateopp) {
    this.service.OpportunitiesSave(ocreateopp).subscribe((result: any) => {
      this.catId = 1;
      $('#createModal').modal('hide');
      this.Getopportunities();
      this.getUseropportunities();
      // this.UpdateOppDetails();
      this.modalForm1.reset();
      this.txtLocation = '';
    });
  }
  CloseDealsRes() {
    this.DealsValidationRes = false;
    this.isDealsResShow = false;
    this.isDealsShow = true;
    this.DealsSavedSuccess = false;
    this.Getopportunities();

  }
  CloseJobRes() {
    this.JobValidationRes = false;
    this.isJobResShow = false;
    this.isJobShow = true;
    this.JobsSavedSuccess = false;
    this.Getopportunities();
  }
  CloseMentorsRes() {
    this.MentorValidationRes = false;
    this.isMentorsResShow = false;
    this.isMentorsShow = true;
    this.MentorsSavedSuccess = false;
    this.Getopportunities();
  }
  newwindow(url) {
    //var w = window.open(url, '_blank', 'height=500,width=500');
    var iMyWidth;
    var iMyHeight;
    //half the screen width minus half the new window width (plus 5 pixel borders).
    iMyWidth = (window.screen.width / 2) - (400);
    //half the screen height minus half the new window height (plus title and status bars).
    iMyHeight = (window.screen.height / 2) - (225);
    //Open the window.
    var win2 = window.open(url, "_blank", "status=no,height=450,width=800,resizable=yes,left=" + iMyWidth + ",top=" + iMyHeight + ",screenX=" + iMyWidth + ",screenY=" + iMyHeight + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    win2.focus();
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.modalForm1.invalid) {
      return;
    }
  }
  getSurveys() {
    this.surservice.GetSurveysList()
      .subscribe((result: any) => {
        if (result) {
          this.noRecords = false;
          this.surveyList = result;
        }
        else {
          this.noRecords = true;
        }
      });
  };
  //getNewsfeed() {
  //  this.nfservice.GetNewsfeed()
  //    .subscribe((result: any) => {
  //      this.newsfeedList = result;
  //    });
  //};
  top() {
    window.scroll(0, 0);
  }

  getTopTopics() {
    this.service.GetTopTopics()
      .subscribe((result: any) => {

        this.topicList = result;
        if (this.topicList.length == 0) {
          this.noToptopicsRec = true;
        } else {
          this.noToptopicsRec = false;
        }
      });
  };
  DashLearn() {
    var data = {
      opportunityCatgid: this.dashOppObj.oppCatId, opportunityDesc: this.dashOppObj.oppDesc, opportunityId: this.dashOppObj.oppId, opportunityRespdesc: this.dashOppObj.oppRespdesc, opportunityRespid: this.dashOppObj.oppResId, opportunityTitle: this.dashOppObj.oppTitle, totalOpportunityDesc: this.dashOppObj.oppTotalOppDesc
    }
    this.LernMore(data);

  }
  FooterDash() {
    if (this.footerOppObj.oppCatId == 1) {
      $('#nav-contact-tab').removeClass('active');
      $('#nav-profile-tab').removeClass('active');
      $('#nav-home-tab').addClass('active');
      $('#nav-home-tab').attr('aria-selected', true);
      $('#nav-profile-tab').attr('aria-selected', false);
      $('#nav-contact-tab').attr('aria-selected', false);
      $('#nav-mentors').removeClass('active');
      $('#nav-jobs').removeClass('active');
      $('#nav-deals').addClass('active');
      this.searchOpp = this.footerOppObj.filter;
    }
    else if (this.footerOppObj.oppCatId == 2) {
      $('#nav-contact-tab').removeClass('active');
      $('#nav-home-tab').removeClass('active');
      $('#nav-profile-tab').addClass('active');
      $('#nav-home-tab').attr('aria-selected', false);
      $('#nav-profile-tab').attr('aria-selected', true);
      $('#nav-contact-tab').attr('aria-selected', false);
      $('#nav-deals').removeClass('active');
      $('#nav-mentors').removeClass('active');
      $('#nav-jobs').addClass('active');
      this.searchJob = this.footerOppObj.filter;
    }
    else if (this.footerOppObj.oppCatId == 3) {
      $('#nav-home-tab').removeClass('active');
      $('#nav-profile-tab').removeClass('active');
      $('#nav-contact-tab').addClass('active');
      $('#nav-home-tab').attr('aria-selected', false);
      $('#nav-profile-tab').attr('aria-selected', false);
      $('#nav-contact-tab').attr('aria-selected', true);
      $('#nav-deals').removeClass('active');
      $('#nav-jobs').removeClass('active');
      $('#nav-mentors').addClass('active');
      this.searchMen = this.footerOppObj.filter;
    }
  }
  btnDealsResDelete(respId) {
    this.delRespId = respId;
    $('#delDlsResModel').modal('show');
  }
  DeleteDealsRes() {
    this.service.OpportunitiesResponseDelete(this.delRespId)
      .subscribe((result: any) => {
        $('#delDlsResModel').modal('hide');
        this.GetopprResp(this.OppId);
      });
  }
  btnJobResDelete(respId) {
    this.jobRespId = respId;
    $('#delJobResModel').modal('show');
  }
  DeleteJobRes() {
    this.service.OpportunitiesResponseDelete(this.jobRespId)
      .subscribe((result: any) => {
        $('#delJobResModel').modal('hide');
        this.GetopprResp(this.OppJobId);
      });
  }
  btnMentorResDelete(respId) {
    this.menRespId = respId;
    $('#delMentorResModel').modal('show');
  }
  DeleteMentorsRes() {
    this.service.OpportunitiesResponseDelete(this.menRespId)
      .subscribe((result: any) => {
        $('#delMentorResModel').modal('hide');
        this.GetopprResp(this.OppMentorId);
      });
  }
  viewJob(joblink) {
    var paramArr = joblink.split(',');
    this.J2c_view(paramArr[0], paramArr[1], paramArr[2], paramArr[3], paramArr[4], paramArr[5], paramArr[6], paramArr[7], paramArr[8], paramArr[9], paramArr[10], paramArr[11], paramArr[12]);
  }
  btnOppDlt(oppId, typeid) {
    this.oppId = oppId;
    if (typeid == 0) {
      this.delType = "Topic";
    }
    else if (typeid == 1) {
      this.delType = "Deal";
    }
    else if (typeid == 2) {
      this.delType = "Job";
    }
    else if (typeid == 3) {
      this.delType = "Mentor";
    }
    $('#delOppModel').modal('show');
  }
  deleteOpp() {
    $('#delOppModel').modal('hide');
    this.service.OpportunitiesDelete(this.oppId)
      .subscribe((result: any) => {
        this.getUseropportunities();
        this.Getopportunities();
      });
  }
  btnDealsTitleDlt() {
    $('#delDlrOppModel').modal('show');
  }
  btnJobTitleDlt() {
    $('#delJobOppModel').modal('show');
  }
  btnMentTitleDlt() {
    $('#delMentOppModel').modal('show');
  }
  DeleteDlrOpp() {
    this.service.OpportunitiesDelete(this.OppId)
      .subscribe((result: any) => {
        $('#delDlrOppModel').modal('hide');
        this.DealsValidationRes = false;
        this.isDealsResShow = false;
        this.isDealsShow = true;
        this.DealsSavedSuccess = false;
        this.Getopportunities();
      });
  }
  DeleteJobOpp() {
    this.service.OpportunitiesDelete(this.OppJobId)
      .subscribe((result: any) => {
        $('#delJobOppModel').modal('hide');
        this.JobValidationRes = false;
        this.isJobResShow = false;
        this.isJobShow = true;
        this.JobsSavedSuccess = false;
        this.Getopportunities();
      });
  }
  DeleteMentorOpp() {
    this.service.OpportunitiesDelete(this.OppMentorId)
      .subscribe((result: any) => {
        $('#delMentOppModel').modal('hide');
        this.MentorValidationRes = false;
        this.isMentorsResShow = false;
        this.isMentorsShow = true;
        this.MentorsSavedSuccess = false;
        this.Getopportunities();
      });
  }
  btnEdit(opprId, catId) {
    this.editesoppId = opprId;
    this.txtUpdLocaReq = '';
    if (catId == 1) {
      this.Opportunities = "Edit Deal";
    }
    else if (catId == 2) {
      this.Opportunities = "Edit Job";
    }
    else if (catId == 3) {
      this.Opportunities = "Edit Mentor";
    }
    this.service.oppById(opprId)
      .subscribe((result: any) => {
        this.catId = result.cat_id;
        this.modalForm1.controls['title'].setValue(result.title);
        this.modalForm1.controls['message'].setValue(result.desc);
        this.txtUpdLocation = result.location;
      });

  }

  UpdateOpp() {
    if (this.modalForm1.invalid) {
      this.submitted = true;
      return;
    }
    else {
      this.UpdateOppDetails();
    }
  }
  UpdateOppDetails() {
    let ocreateopp: CreateOpp = new CreateOpp();
    //ocreateopp.cat_id = this.catId;
    ocreateopp.title = this.modalForm1.value.title;
    ocreateopp.desc = this.modalForm1.value.message;
    ocreateopp.oppr_id = this.editesoppId;
    ocreateopp.location = this.txtUpdLocation;
    this.service.OpportunitiesUpdate(ocreateopp).subscribe((result: any) => {
      $('#updateModal').modal('hide');
      this.getUseropportunities();
      this.modalForm1.reset();
    });
  }
  getUserRole() {
    this.service.getUserRole().subscribe((result: any) => {
      if (result == "adminUser") {
        this.adminUser = true;
      }
      else {
        this.adminUser = false;
      }
    });
  }
  J2c_view(o, m, k, e, g, f, j, i, h, l, n, p, d) {
    window.open("https://www.jobs2careers.com/click.php?jid=" + (k >>> 0).toString(16) + ("0000000" + (m >>> 0).toString(16)).slice(-8) + ("0000000" + (o >>> 0).toString(16)).slice(-8) + (typeof j != "undefined" && j.toString() ? "&t1=" + j : "") + (typeof i != "undefined" && i.toString() ? "&t2=" + i : "") + (typeof h != "undefined" && h.toString() ? "&t3=" + h : "") + (typeof l != "undefined" ? l : "") + "&ri=" + n + (p ? ("&query_category_id=" + p) : "") + "&q=" + encodeURIComponent(d) + "&r=" + encodeURIComponent(window.location.href));
  }
  SaveRes() {
  }
}

export class Resp {
  oppdesc: string;
  oppid: number;
  username: string;
  title: string;
}

export class CreateOpp {
  cat_id: number;
  title: string;
  desc: string;
  oppr_id: number;
  location: string;
}
