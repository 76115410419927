import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { VariablesService } from '../common/services/variables.service';
declare var $: any;

@Component({
  selector: 'app-donotsellmydata',
  templateUrl: './donotsellmydata.component.html',
  styleUrls: ['./donotsellmydata.component.css']
})
export class DonotsellmydataComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private service: VariablesService) { }

  DoNotSellInfoForm: FormGroup;
  donotsell: dnsmd[];
  submitted = false;
  showMsg: boolean = true;
  errMsg: boolean = true;
  captchaErr: boolean = true;
  requestid: string;
  msg: any;
  recaptchaError: boolean = false;
  isReadOnly: boolean = false;
  emailchk: any;
  loading: boolean = false;
  get f() {
    return this.DoNotSellInfoForm.controls;
  }
  
  ngOnInit() {

    this.DoNotSellInfoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      lastName: ['', Validators.required],
      siteaddress: ['', Validators.required],
      requesttype: ['', Validators.required],

    });
    
    this.showMsg = false;
    this.errMsg = false;
    this.captchaErr = false;
  }

  
onSubmit() {
    this.recaptchaError = false;
    this.submitted = true;
    if (this.DoNotSellInfoForm.invalid) {
      return;
    }
  if ($('#g-recaptcha-response').val() != "") {
    let odnsmd: dnsmd = new dnsmd();
    odnsmd.firstName = this.DoNotSellInfoForm.value.firstName,
      odnsmd.lastName = this.DoNotSellInfoForm.value.lastName;
    odnsmd.email = this.DoNotSellInfoForm.value.email;
    odnsmd.siteaddress = this.DoNotSellInfoForm.value.siteaddress;
    odnsmd.requesttypeId = this.DoNotSellInfoForm.value.requesttype;
    if (odnsmd.requesttypeId == 0) {
      odnsmd.RequestName = 'Request to delete my personal info';
    }
    else if (odnsmd.requesttypeId == 1) {
      odnsmd.RequestName = 'Request to opt-out of sale of personal info';
    }
    else if (odnsmd.requesttypeId == 2) {
      odnsmd.RequestName = 'Request to know about my personal info';
    }
    else if (odnsmd.requesttypeId == 3) {
      odnsmd.RequestName = 'Other privacy or account management request';
    }

    this.service.getDonotSellmyData(odnsmd)
      .subscribe((result: any) => {
        if (result == 1) {
          this.showMsg = true;
          this.errMsg = false;
          this.msg = "Your Query has been submitted successfully. We will get back to you ASAP.";
        } else if (result == 0) {
          this.errMsg = true;
          this.showMsg = false;
          this.msg = "Msg send failure, Please check network connection Try again";
        } else {
          this.msg = "Please Fill All Feilds";
          this.ngOnInit();
          this.submitted = true;
        }

         this.resetForm(this.DoNotSellInfoForm);
        setTimeout(() => {
          this.showMsg = null;
        }, 3000);
      })
  }
  else {
    this.recaptchaError = true;
  }

    //this.ngOnInit();
}

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }

}
export class dnsmd {
  firstName: string;
  lastName: string;
  email: string;
  siteaddress: string;
  requesttypeId : number;
  RequestName: string;
}
