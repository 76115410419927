import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { newsfeedService } from '../common/services/newsfeed.service';
declare var $: any;

@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.css']
})

export class NewsfeedComponent implements OnInit {

  modalForm1: FormGroup;
  submitted = false;
  newsfeedList: any[] = [];
  id: any;
  title: any;
  message: any;
  newsfeedSelectedList: any;
  Heading: any;
  ShowSave: boolean = false;
  ShowUpdate: boolean = false;
  ShowDelete: boolean = false;
  newsfeedid: number;
  

  constructor(private formBuilder: FormBuilder, private nfservice: newsfeedService, private service: newsfeedService) { }
  newsfeed : nwsfdsave[];
  ngOnInit() {
    this.modalForm1 = this.formBuilder.group({
      title: ['', [Validators.required]],
      message: ['', Validators.required],
    });
    this.getNewsfeed();
  }

  get f() {
    return this.modalForm1.controls;
  }

  getNewsfeed() {
    this.nfservice.GetNewsfeedAll()
      .subscribe((result: any) => {
        this.newsfeedList = result;
      });
  };

  Create() {
    this.modalForm1.reset();
    this.Heading = "Create Newsfeed";
    this.ShowSave = true;
    this.ShowUpdate = false;
    this.ShowDelete = false;
  }

  close() {
    this.modalForm1.reset();
  }

  CreateNewsfeed() {
    if (this.modalForm1.invalid) {
      this.submitted = true;
      return;
    }
    else {
      this.SaveNewsfeed();
    }
  }

  SaveNewsfeed(id = 0) {
    let onwsfd: nwsfdsave = new nwsfdsave();
    onwsfd.nfd_id = id;
    onwsfd.nfd_title = this.modalForm1.value.title;
    onwsfd.nfd_message = this.modalForm1.value.message;
    this.service.SaveNewsfeed(onwsfd).subscribe((result: any) => {
      $('#createModal').modal('hide');
      this.getNewsfeed();
      this.modalForm1.reset();
    });
  }

  Update() {
    this.id = this.newsfeedid;

    if (this.modalForm1.invalid) {
      this.submitted = true;
      return;
    }
    else {
      let onwsfd: nwsfdsave = new nwsfdsave();
      onwsfd.nfd_id = this.id;
      onwsfd.nfd_title = this.modalForm1.value.title;
      onwsfd.nfd_message = this.modalForm1.value.message;
      this.service.UpdateNewsfeed(onwsfd).subscribe((result: any) => {
        $('#createModal').modal('hide');
        this.getNewsfeed();
        this.modalForm1.reset();
      });
    }
  }

  select(id) {
    this.nfservice.SelectNewsfeed(id)
      .subscribe((result: any) => {
        this.newsfeedSelectedList = result;
        this.title = this.newsfeedSelectedList[0].newsfeedTitle;
        this.message = this.newsfeedSelectedList[0].newsfeedDesc;
        $('#createModal').modal('show');

        this.newsfeedid = id;
      });

    this.Heading = "Edit Newsfeed";
    this.ShowUpdate = true;
    this.ShowSave = false;
    this.ShowDelete = true;
  }

  confirmation() {
    if (confirm("Are you sure to delete?")) {
      console.log(this.Delete());
    }
    else {
      return;
    }
  }

  Delete() {
    this.id = this.newsfeedid;
    this.nfservice.DeleteNewsfeed(this.id)
      .subscribe((result: any) => {
        this.getNewsfeed();
        $('#createModal').modal('hide');
      });   
  }

}

export class nwsfdsave {
  nfd_id: number;
  nfd_title: string;
  nfd_message: string;
}
