import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIGURATION } from '../../app.constants';
import { cont } from '../../contactus/contactus.component';
import { UserLogin } from '../../login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { dnsmd } from '../../donotsellmydata/donotsellmydata.component';
import { dashOpp } from '../../dashboard/dashboard.component';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {
  url: any;
  constructor(private location: Location, private http: HttpClient, private cookieService: CookieService) {
    this.url = CONFIGURATION.baseUrls.ServerPath

  }
  current_url: any = this.location.path();
  login(body: UserLogin) {
    const url1 = this.url + 'Login/Login';
    var data = { Username: body.Username, Password: body.Password }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<UserLogin>(url1, data, { headers });
  }
  getUserDetails() {
    return this.http.get(this.url + 'Login/GetUserData');
  }
  getUserData(UserEmail) {
    return this.http.get(this.url + 'Login/GetUserDetails?UserEmail=' + UserEmail);
  }
  forgotPassword(emailAdd, orgId, campId, CustomAttribute) {
    return this.http.get(this.url + 'Login/ForgetPassword?emailAddress=' + emailAdd + '&orgId=' + orgId + '&campid=' + campId + '&CustomAttribute=' + CustomAttribute);
  }
  GetCurrentDomainDetails() {
    return this.http.get(this.url + 'Login/GetCurrentDomainDetails');
  }

  getDonotSellmyData(body: dnsmd) {
    const url1 = this.url + 'Login/SaveDoNotSellMyInfo';
    var data = { firstName: body.firstName, lastName: body.lastName, email: body.email, siteaddress: body.siteaddress, requesttype: body.requesttypeId.toString(), requestname: body.RequestName }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<dnsmd>(url1, data, { headers });
  }

  DiscourseLogout() {
    return this.http.get(this.url + 'Discourse/ssoLogout');
  }
  DiscourseLoginreqtrue() {
    return this.http.get(this.url + 'Discourse/LoginRequiredTrue');
  }
  DiscourseLoginreqFalse() {
    return this.http.get(this.url + 'Discourse/LoginRequiredFalse');
  }
  DiscourseNotifications() {
    return this.http.get(this.url + 'Discourse/getNotificationscount');
  }
  DiscourseNotificationsdata() {
    return this.http.get(this.url + 'Discourse/getNotifications');
  }
  GetPostLikes() {
    return this.http.get(this.url + 'Discourse/getpostslikes', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
  ContactusSave(body: cont) {
    const url1 = this.url + 'Login/SendEmail';
    var data = { Name: body.Name, email: body.email, comments: body.comments }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<cont>(url1, data, { headers });
  }
  tokenEmpty() {
    return this.http.get(this.url + 'Login/tokenEmpty', { responseType: 'text' });

  }
  getadminUserFlag() {
    return this.http.get(this.url + 'Discourse/getadminUserFlag', { responseType: 'text' });
  }
  getlatesttopics() {
    return this.http.get(this.url + 'Discourse/getlatesttopics', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
  getcategorieslist() {
    return this.http.get(this.url + 'Discourse/getcategorieslist', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
  getsingletopicpostsbyid(id) {
    return this.http.get(this.url + 'Discourse/getsingletopicpostsbyid?id=' + id, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
  topicreply(body: dashOpp) {
    //return this.http.get(this.url + 'Discourse/topicreply?id=' + id + '&desc=' + desc, {
    //  headers: new HttpHeaders(
    //    {
    //      'Authorization': 'Bearer ' + this.cookieService.get('token'),
    //    })
    //});
    const url1 = this.url + 'Discourse/topicreply';
    var data = { topicId: Number(body.topicId), txtReplyDesc: body.txtReplyDesc }
    return this.http.post<dashOpp>(url1, data, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
          'content-type': 'application/json'
        })
    });
  }
  createnewtopic(body: dashOpp) {
    const url1 = this.url + 'Discourse/createnewtopic';
    var data = { topictitle: body.topictitle, topicbody: body.topicbody, catId: Number(body.catId) }
    return this.http.post<dashOpp>(url1, data, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
          'content-type': 'application/json'
        })
    });
  }

  likepost(id) {
    return this.http.get(this.url + 'Discourse/likesapost?id=' + id, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
  getlatestpagetopics(pageNo) {
    return this.http.get(this.url + 'Discourse/getlatesttopicpages?pageNo=' + pageNo, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
  sessionExist() {
    return this.http.get(this.url + 'Opportunities/SessionExist', { responseType: 'text' });
  }

  getcategorytopics(catgid) {
    return this.http.get(this.url + 'Discourse/getcategorytopics?catgid=' + catgid, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
  notificationlevel(tid, nfnlvl) {
    return this.http.get(this.url + 'Discourse/topicnotifications?tid=' + tid + '&nfnlvl=' + nfnlvl, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
  GetUserName(userId) {
    return this.http.get(this.url + 'Login/GetUserNameByUserId?userId=' + userId);
  }
  UnsubscribeUser(userEmail) {
    return this.http.get(this.url + 'Login/UncsubscribeUser?userEmail=' + userEmail);
  }
  userSessionData() {
    return this.http.get(this.url + 'Login/userSessionData', { responseType: 'text' });
  }
  DiscourseUnsubscribeUser(userName) {
    return this.http.get(this.url + 'Discourse/Unsubscribe?userName=' + userName);
  }
  getsingletopicpostsuserbyid(tid) {
    return this.http.get(this.url + 'Discourse/getusersingletopicpostsbyid?tid=' + tid, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
  GetUserCountByUserEmail(userEmail) {
    return this.http.get(this.url + 'Login/GetUserCountByUserEmail?userEmail=' + userEmail);
  }

}
