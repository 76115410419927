import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class NeedsAuthentication implements CanActivate {
  token: any;
  constructor(private router: Router, private cookieService: CookieService) {
    this.token = this.cookieService.get('token');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.token) {
      return true;
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }

  }

}
