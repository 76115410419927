import { Component, OnInit } from '@angular/core';
import { VariablesService } from './../common/services/variables.service'
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AuthService, FacebookLoginProvider, SocialUser } from 'angularx-social-login';
import { registrationService } from '../common/services/registration.service';
import { seoservice } from './../common/services/seo.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  forgotForm: FormGroup;
  submitted = false;
  fsubmitted = false;
  constructor(private service: VariablesService, private regservice: registrationService, private router: Router, private formBuilder: FormBuilder, private authService: AuthService, private cookieService: CookieService, private SeoService: seoservice) { }
  userData: any = [];
  Jwtoken: any;
  userGuid: any;
  errorMessage: any;
  isError: boolean = false;
  email_req: boolean = false;
  password_req: boolean = false;
  isLogin: boolean = false;
  forgotUserData: any = [];
  fbUserData: any = [];
  orgDetails: any = [];
  isInvalidEmail: boolean = false;
  user: any;
  loggedIn: any;

  ngOnInit() {
    this.SeoService.setPageMetaData('Login | The most rewarding business community - Conversant.pro', 'Connect with peers, business opportunities and industry news while earning rewards for participation in the community and business surveys.');
    this.service.userSessionData().subscribe((result: any) => {
        if (result != 0) {
          window.location.href = '/dashboard';
        }
      });
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],

    });
    this.forgotForm = this.formBuilder.group({
      forgotEmail: ['', [Validators.required, Validators.email]]
    });
    if (performance.navigation.type == 2) {
      window.location.href = '/dashboard';
    }
  }
  get f() {
    return this.registerForm.controls;
  }
  get p() {
    return this.forgotForm.controls;
  }
  login() {
    if (this.registerForm.invalid) {
      return;
    }
    else {
      this.userLogin();
    }
  }
  signup() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
  }
  forgotup() {
    this.fsubmitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
  }
  userLogin() {
    let ologin: UserLogin = new UserLogin();
    ologin.Username = this.registerForm.value.email;
    ologin.Password = this.registerForm.value.password
    this.service.login(ologin).subscribe((data) => {
      this.userData = data;
      if (!this.userData.errorMessage) {
        this.Jwtoken = this.userData.token;
        this.userGuid = this.userData.userGuid;

        this.service.DiscourseLoginreqtrue().subscribe((result: any) => {
        });
        if (this.userData.adminUser == true) {
          window.location.href = '/dashboard';
        }
        else {
          window.location.href = '/dashboard';
        }
      }
      else {
        this.isError = true;
        this.errorMessage = this.userData.errorMessage;

      }
    });
  }
  forgotClick() {

    this.isLogin = true;
  }
  forgotCancel() {
    this.isLogin = false;
    this.forgotForm.controls['forgotEmail'].setValue(null);
  }
  forgotSubmit() {
    if (this.forgotForm.invalid) {
      return;
    }
    else {
      this.forgotUserDetails();
    }
  }
  forgotUserDetails() {
    this.isInvalidEmail = false;
    this.service.GetCurrentDomainDetails().subscribe((data) => {
      this.orgDetails = data;
      this.service.getUserData(this.forgotForm.value.forgotEmail).subscribe((data) => {
        this.forgotUserData = data;
        if (this.forgotUserData.emailAddress) {
          this.isInvalidEmail = false;
          var CustomAttribute = "first_name:" + this.forgotUserData.firstName + ";last_name:"
            + this.forgotUserData.lastName + ";email_address:" + this.forgotUserData.emailAddress + ";password:"
            + this.forgotUserData.password + ";create_dt:" + this.forgotUserData.createDate + ";ip_address:"
            + this.forgotUserData.ipAddress + ";org_logo:" + this.orgDetails.orgLogo.replace("http://", "") + ";org_name:" + this.orgDetails.orgName
            + ";member_url:" + this.orgDetails.memberUrl + ";user_guid:" + this.forgotUserData.userGuid;

          this.service.forgotPassword(this.forgotUserData.emailAddress, this.forgotUserData.orgId, 803, CustomAttribute).subscribe((data) => {
            this.isLogin = false;
            this.forgotForm.controls['forgotEmail'].setValue(null);
          });
        }
        else {
          this.isInvalidEmail = true;
        }
      });
    });
  }

  //signInWithFB(): void {
  //  let socialPlatformProvider;
  //  socialPlatformProvider = this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  //  this.authService.signIn(socialPlatformProvider).then(socialusers => {
  //    if (socialusers != null) {
  //    }
  //  });
  //  this.authService.authState.subscribe((user) => {
  //    if (user != null) {
  //      let userSocial = new UserSocial();
  //      userSocial.FBId = user.id;
  //      userSocial.FBToken = user.authToken;
  //      userSocial.EmailAddress = user.email;
  //      userSocial.FirstName = user.firstName;
  //      userSocial.LastName = user.lastName;
  //      userSocial.FBPhotoUrl = user.photoUrl;
  //      this.CheckFBUserData(userSocial);
  //    }
  //  });

  //}
  //Check fbemail in database
  CheckFBUserData(socialusers: UserSocial) {
    this.service.getUserDetails().subscribe((data) => {
      this.userData = data;
      //this.fbUserData = data;
      if (this.userData.emailAddress) {
        this.Jwtoken = this.userData.token;
        this.userGuid = this.userData.userGuid;
        //this.cookieService.set('token', this.Jwtoken);
        //this.cookieService.set('userGuid', this.userGuid);
        //this.cookieService.set('userName', this.userData.emailAddress);
        this.service.DiscourseLoginreqtrue().subscribe((result: any) => {
        });

        window.location.href = '/dashboard';
      }
      else {
        let member = new UserSocial();
        member.EmailAddress = socialusers.EmailAddress;
        member.FirstName = socialusers.FirstName;
        member.LastName = socialusers.LastName;
        member.FBId = socialusers.FBId;
        member.FBToken = socialusers.FBToken;
        member.FBPhotoUrl = socialusers.FBPhotoUrl;
        member.Rid = 20809;
        this.regservice.FaceBookRegistration(member)
          .subscribe((result: Member) => {
          });

      }
      window.location.href = '/dashboard';
    });
  }
  regClick() {
    localStorage.setItem('mode', 'regCreate');
    window.location.href = '/registration';
  }
}

export class UserSocial {
  ExtMemberId: string;
  Rid: number;
  TxId: string;
  Country: string;
  State: string;
  FirstName: string;
  LastName: string
  EmailAddress: string;
  Zip: string;
  Gender: string;
  Dob: string;
  Address1: string;
  Address2: string;
  Ethnicity: string;
  City: string;
  DomainUrl: string;
  UserGuid: string;
  FBId: string;
  FBToken: string;
  FBPhotoUrl: string;
}
export class Member {
  ExtMemberId: string;
  Rid: number;
  TxId: string;
  Country: string;
  State: string;
  FirstName: string;
  LastName: string
  EmailAddress: string;
  Zip: string;
  Gender: string;
  Dob: string;
  Address1: string;
  Address2: string;
  Ethnicity: string;
  City: string;
  DomainUrl: string;
  UserGuid: string;
}
export class UserLogin {
  Username: string
  Password: string;
}

