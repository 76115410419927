import { Component, OnInit } from '@angular/core';
import { registrationService } from '../common/services/registration.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-regstep1',
  templateUrl: './regstep1.component.html',
  styleUrls: ['./regstep1.component.css']
})
export class Regstep1Component implements OnInit {

  constructor(private service: registrationService, private formBuilder: FormBuilder) { }
  countrystateList: any = [];
  countryList: any = [];
  ethinicityList: any = [];
  yearLst: any = [];
  currentYear: any;
  regstep1Form: FormGroup;
  submitted = false;
  get f() {
    return this.regstep1Form.controls;
  }
  ngOnInit() {
    this.regstep1Form = this.formBuilder.group({
      country: ['', Validators.required],
      gender: ['', Validators.required],
      month: ['', Validators.required],
      date: ['', Validators.required],
      year: ['', Validators.required],
      zipCode: ['', Validators.required],
      ethnicity: ['', Validators.required]
    });
    this.GetCountrysAndStates();
    this.GetEthinicity();
    this.bindingYears();
  }

  GetCountrysAndStates() {
    this.service.GetCountrysAndStates()
      .subscribe((result) => {
        this.countrystateList = result;
        this.countryList = this.countrystateList.countryList;
      });
  }

  GetEthinicity() {
    this.service.GetEthinicity()
      .subscribe((result) => {
        this.ethinicityList = result;
      });
  }
  bindingYears() {
    //beind years
    var d = new Date();
    this.currentYear = d.getFullYear();
    for (var i = 13; i < 100; i++) {
      this.yearLst.push({ key: this.currentYear - i, value: this.currentYear - i });
    }
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.regstep1Form.invalid) {
      return;
    }
  }
}
