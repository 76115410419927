import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControlName } from '@angular/forms';
import { VariablesService } from './../common/services/variables.service';
@Component({
  templateUrl: './unsubscribe.component.html'
})
export class UnsubscribeComponent implements OnInit {
  userEmailForm: FormGroup;
  fsubmitted = false;
  constructor(private router: Router, private service: VariablesService, private route: ActivatedRoute, private formBuilder: FormBuilder) { }
  userName: string;
  userId: number;
  isUnsubscribe: boolean = false;
  unsubscribemesg: any;
  emailAddress: any;
  userEmailCount: any;
  invalidEmail: boolean = false;
  ngOnInit() {
    this.invalidEmail = false;
    this.userName = "";
    this.route.params.subscribe(
      params => {
        this.userId = params['id']
      });
    if (!this.userId) {
      this.getUserData();
    }
    else {
      this.GetUserName();
    }
    this.userEmailForm = this.formBuilder.group({
      userEmail: ['', [Validators.required, Validators.email]]
    });
  }
  get p() {
    return this.userEmailForm.controls;
  }
  UserUnsubscribe() {
    this.unsubscribemesg = "";
    this.invalidEmail = false;
    this.fsubmitted = true;
    if (this.userEmailForm.invalid) {
      return;
    }
    else {
      this.GetUserCountByUserEmail();
    }
  }
  GetUserName() {
    if (this.userId != 0) {
      this.service.GetUserName(this.userId)
        .subscribe((res: any) => {
          this.userName = res.userName;
          this.emailAddress = res.emailAddress;
        });
    }
  }
  getUserData() {
    this.service.userSessionData().subscribe((res: any) => {
      this.userId = res;
      this.GetUserName();
    });
  }
  GetUserCountByUserEmail() {
    this.service.GetUserCountByUserEmail(this.emailAddress).subscribe((res: any) => {
      this.userName = res.userName;
      //this.userEmailCount = res.userCount;
      if (this.userName) {
        this.UnsubscribeUser();
      }
      else {
        this.invalidEmail = true;
      }
    });
  }
  UnsubscribeUser() {
    this.service.UnsubscribeUser(this.emailAddress)
      .subscribe((res: any) => {
        this.service.DiscourseUnsubscribeUser(this.userName)
          .subscribe((res: any) => {
            if (res == true) {
              this.isUnsubscribe = true;
              this.unsubscribemesg = "Unsubscribed Successfully"
            }
          });
      });
  }
  emailup() {
    this.fsubmitted = true;
    if (this.userEmailForm.invalid) {
      return;
    }
  }
}
