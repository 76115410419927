import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIGURATION } from '../../app.constants';
import { CookieService } from 'ngx-cookie-service';
import { nwsfdsave } from '../../newsfeed/newsfeed.component';

@Injectable({
  providedIn: 'root'
})
export class newsfeedService {
  url: any;
  userName: any;
  constructor(private location: Location, private http: HttpClient, private cookieService: CookieService) {
    this.url = CONFIGURATION.baseUrls.ServerPath;
  }

  GetNewsfeed() {
    return this.http.get(this.url + 'Newsfeed/NewsfeedGet', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }

  GetNewsfeedAll() {
    return this.http.get(this.url + 'Newsfeed/NewsfeedGetAll', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }

  SelectNewsfeed(newsfeed_id) {
    return this.http.get(this.url + 'Newsfeed/NewsfeedSelect?newsfeedId=' + newsfeed_id, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }

  DeleteNewsfeed(newsfeed_id) {
    return this.http.get(this.url + 'Newsfeed/NewsfeedDelete?newsfeedId=' + newsfeed_id, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }

  SaveNewsfeed(body: nwsfdsave) {
    const url1 = this.url + 'Newsfeed/NewsfeedSave';
    var data = { nfd_id: body.nfd_id, nfd_title: body.nfd_title, nfd_message: body.nfd_message }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<nwsfdsave>(url1, data, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
    //return this.http.get(this.url + 'Newsfeed/NewsfeedSave?newsfeedTitle=' + newsfeedTitle + '&newsfeedDesc=' + newsfeedDesc + '&newsfeedId=' + id, {
    //  headers: new HttpHeaders(
    //    {
    //      'Authorization': 'Bearer ' + this.cookieService.get('token')
    //    })
    //});
  }

  UpdateNewsfeed(body: nwsfdsave) {
    const url1 = this.url + 'Newsfeed/NewsfeedUpdate';
    var data = { nfd_id: body.nfd_id, nfd_title: body.nfd_title, nfd_message: body.nfd_message }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<nwsfdsave>(url1, data, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }

}
