import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VariablesService } from '../common/services/variables.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private service: VariablesService, private router: Router) { }
  contactusForm: FormGroup;
  submitted = false;
  contact: cont[];
  msg: string = null;

  get f() {
    return this.contactusForm.controls;
  }
  ngOnInit() {
      this.contactusForm = this.formBuilder.group({
      Name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      comments: ['', Validators.required],

    });
  }
  onSubmit() {

    this.submitted = true;
    if (this.contactusForm.invalid) {
      return;
    }
    let ocont: cont = new cont();
      ocont.Name = this.contactusForm.value.Name,
      ocont.email = this.contactusForm.value.email,
        ocont.comments = this.contactusForm.value.comments
    this.service.ContactusSave(ocont)
      .subscribe((result: any) => {
        if (result == 1) {
          this.msg = "Your Query has been submitted successfully. We will get back to you ASAP.";
        } else if (result == 0) {
          this.msg = "Msg send failure, Please check network connection Try again";
        } else {
          this.msg = "Please Fill All Feilds";
          this.ngOnInit();
          this.submitted = true;
        }
         
        this.resetForm(this.contactusForm);
        setTimeout(() => {
          this.msg = null;
        }, 3000);
      })
    //this.ngOnInit();
  }
  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }
}

export class cont {
  Name: string;
  email: string;
  comments: string;

}
