import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';

  showHead: boolean;
  showFoot: boolean;

  constructor(private router: Router) {
    
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/privacypolicy' || event['url'] == '/termsandconditions' || event['url'] == '/cookiepolicy' || event['url'] == '/aboutrewards' || event['url'] == '/donotsellmydata' || event['url'] == '/regstep1') {
          this.showHead = false;
          this.showFoot = false;
        } else {
          
          this.showHead = true;
          this.showFoot = true;
        }
      }
    });

  }
}
