import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VariablesService } from './../common/services/variables.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { seoservice } from './../common/services/seo.service';
declare var $: any;
@Component({
  selector: 'app-circles',
  templateUrl: './circles.component.html',
  styleUrls: ['./circles.component.css']
})
export class CirclesComponent implements OnInit {

  constructor(private router: Router, private service: VariablesService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private route: ActivatedRoute, private titleService: Title, private metaTagService: Meta, private location: Location, private SeoService: seoservice) { }
  createtopicForm: FormGroup;
  submitted = false;
  topicsData: any[] = [];
  categData: any[] = [];
  topicResponce: any[] = [];
  isTopics: boolean = true;
  titlecatgname: any;
  catgcolor: any;
  topicTitle: any;
  topicDetails: any;
  topicId: any;
  txtReplyDesc: any;
  txtTopicTitle: any;
  txtTopicBody: any;
  ddlCatId: any;
  suggestedtopics: any;
  topiclike: any;
  resplike: any;
  topicdata: any;
  topicresplength: any;
  lstpstname: any;
  lstpostedat: any;
  topreplies: any;
  topviews: any;
  topusers: any;
  toplikes: any;
  topicuser1: any;
  topicuser2: any;
  topicusers: any;
  pageNo: any = 1;
  TopicReplyValidation: boolean = false;
  TopicReplyLengthValidation: boolean = false;
  pageTab: any = "categories";
  isLatestBtn: boolean = true;
  catgtype: string;
  selcatgid: string;
  showcat: boolean = true;
  catgtopicsdata: any[] = [];
  noTopicRecords: boolean = false;
  noCatTopicRecords: boolean = false;
  searchOpp: any;
  get f() {
    return this.createtopicForm.controls;
  }

  ngOnInit() {
    this.SeoService.setPageMetaData('Conversations | Discuss topics with peers - Conversant.pro', 'Earn rewards while keeping up with the latest trends in your field by participating in community discussions.');
    //this.SeoService.createCanonicalURL();
    this.catgtype = "Latest";
    this.getlatesttopics();
    this.createtopicForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(15)]],
      message: ['', [Validators.required, Validators.minLength(20)]],
      category: ['', Validators.required]
    });

    this.route.params.subscribe(
      params => {
        if (params['title'] && params['id']) {
          if (params['tab'] =='c') {
            this.pageTab = "categories";
          }
          else if (params['tab'] == 'l') {
            this.pageTab = "Latest";
          }
          this.getResponses(params['id'], params['title'], this.pageTab);
        }
      });

    //this.titleService.setTitle("The most rewarding business community - Conversant.pro");
    //this.metaTagService.updateTag({ name: 'description', content: 'Article Description' }, "name='description'");
    //this.metaTagService.addTags([
    //  { name: 'keywords', content: 'Finance,Healthcare,HR,Information Technology,Leadership & Strategy,Marketing & Advertising,Product/Graphic Design,Sales,Security,Site Feedback,Supply Chain/Logistics,Trending News' },
    //  //{ name: 'robots', content: 'index, follow' },
    //  { name: 'author', content: 'Conversant.pro' },
    //  { name: 'description', content: 'Connect with peers to solve challenges, business opportunities and industry news while earning rewards for participation in the community and business surveys' },
    //  { charset: 'UTF-8' }
    //]);
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    // stop here if form is invalid
    if (this.createtopicForm.invalid) {
      this.spinner.hide();
      return;
    }
    let dealrsp: dashOpp = new dashOpp();
    dealrsp.topictitle = this.txtTopicTitle;
    dealrsp.topicbody = this.txtTopicBody;
    dealrsp.catId = this.ddlCatId;
    this.topicsData = [];
    this.service.createnewtopic(dealrsp)
      .subscribe((res: any) => {
        $('#staticBackdrop').modal('hide');
        this.getlatesttopics();
      });
  }
  
  getlatesttopics() {
    this.spinner.show();
    this.service.getlatesttopics()
      .subscribe((result: any) => {
        this.topicsData = result.topic_list.topics;
        if (this.topicsData.length == 0) {
          this.noTopicRecords = true;
        } else {
          this.noTopicRecords = false;
        }
        this.catgtopicsdata = result.topic_list.topics;
        if (this.catgtopicsdata.length == 0) {
          this.noCatTopicRecords = true;
        } else {
          this.noCatTopicRecords = false;
        }
        this.service.getcategorieslist()
          .subscribe((res: any) => {
            this.categData = res;
            this.topicsData.forEach(e => {
              this.categData.forEach(opt => {
                if (e.category_id == opt.id) {
                  e.category_name = opt.name;
                  e.background_colour = '#' + opt.color;
                  e.text_color = '#' + opt.text_color;
                }
              })
            });
            if (this.pageTab == "Latest") {
              this.highlightedLatestTab();
            }
            else {
              this.highlightedCatgTab();
            }
            this.spinner.hide();
            this.spinner.hide();
            this.spinner.hide();

          });
      });
  }
  getResponses(id, title, pagename) {
    this.pageTab = pagename;
    this.topicId = id;
    this.isTopics = false;
    this.topicDetails = '';
    this.topicTitle = '';
    this.titlecatgname = '';
    this.catgcolor = '';
    this.topicResponce = [];
    //this.spinner.show();
    this.service.getsingletopicpostsbyid(id)
      .subscribe((res: any) => {
        this.topicdata = res;
        this.topreplies = res.posts_count - 1;
        this.topviews = res.views;
        this.topusers = res.participant_count;
        this.toplikes = res.like_count;
        this.topicusers = res.details.participants;
        //this.topicuser1 = res.details.participants[0].username;
        //this.topicuser2 = res.details.participants[1].username;
        this.topicDetails = res.post_stream.posts.filter(e => e.post_number == 1)[0];
        this.topicTitle = res.title;
        this.titlecatgname = res.category_name;
        this.catgcolor = "#" + res.category_color;
        this.topiclike = res.post_stream.posts[0].actions_summary[0].count;
        this.topicResponce = res.post_stream.posts.filter(e => e.post_number != 1);
        this.topicresplength = this.topicResponce.length;
        this.lstpstname = res.details.last_poster.username;
        this.lstpostedat = res.last_posted_at;
        //this.resplike = res.post_stream.posts.filter(e => e.post_number != 1)[0].actions_summary[0].count;
        this.suggestedtopics = res.suggested_topics;
        this.spinner.hide();
        this.spinner.hide();
        window.scroll(0, 100)
        //this.location.go('circles/t/' + res.slug + '/' + id);
        // this.spinner.hide();
      });
  }
  bactoTopic() {
    this.catgtype = "Latest";
    this.topicId = '';
    this.topicDetails = '';
    this.topicTitle = '';
    this.titlecatgname = '';
    this.catgcolor = '';
    this.topicResponce = [];
    this.txtReplyDesc = '';
    this.TopicReplyValidation = false;
    this.TopicReplyLengthValidation = false;
    this.topicsData = [];
    this.categData = [];
    this.isTopics = true;
    this.isLatestBtn = true;
    this.pageNo = 1;
    this.getlatesttopics();
    this.location.go('circles');
  }
  onTopicReply() {
    alert("Please login to Reply");
    //this.spinner.show();
    //this.TopicReplyValidation = false;
    //this.TopicReplyLengthValidation = false;
    //if (!this.txtReplyDesc) {
    //  this.TopicReplyValidation = true;
    //  this.spinner.hide();
    //}
    //else {
    //  if (this.txtReplyDesc.length > 20) {
    //    this.sendreply();
    //  }
    //  else {
    //    this.TopicReplyLengthValidation = true;
    //    this.spinner.hide();
    //  }
    //}
  }

  sendreply() {
    let dealrsp: dashOpp = new dashOpp();
    dealrsp.topicId = this.topicId;
    dealrsp.txtReplyDesc = this.txtReplyDesc;
    this.service.topicreply(dealrsp)
      .subscribe((res: any) => {
        this.txtReplyDesc = '';
        this.getResponses(this.topicId, this.topicTitle, this.pageTab);
      });
  }

  createnewtopic() {
    alert("Please login to Create Topic");
    //this.txtTopicTitle = '';
    //this.txtTopicBody = '';
    //this.ddlCatId = '';
  }


  MoreLatest() {
    this.highlightedLatestTab();
    window.scroll(0, 100);
  }
  brouseCatg() {
    this.isTopics = true;
    this.highlightedCatgTab()
    window.scroll(0, 100);
  }


  postlikes(id) {
    alert("Please login to Like Post");
    //this.spinner.show();
    //this.service.likepost(id).subscribe((res: any) => {
    //  if (res == true) {
    //    // this.topiclike = this.topiclike + 1;
    //    this.getResponses(this.topicId, this.topicTitle, this.pageTab);
    //  }
    //  else {
    //    this.spinner.hide();
    //  }
    //});

  }
  highlightedCatgTab() {
    $('#pills-home-tab').addClass('active');
    $('#pills-home-tab').attr('aria-selected', true);
    $('#pills-home').addClass('active');
    $('#pills-profile-tab').removeClass('active');
    $('#pills-profile-tab').attr('aria-selected', false);
    $('#pills-profile').removeClass('active');
    $('#pills-home').addClass('show');
    $('#pills-profile').removeClass('show');
  }
  highlightedLatestTab() {
    $('#pills-home-tab').removeClass('active');
    $('#pills-home-tab').attr('aria-selected', false);
    $('#pills-home').removeClass('active');
    $('#pills-profile-tab').addClass('active');
    $('#pills-profile-tab').attr('aria-selected', true);
    $('#pills-profile').addClass('active');
    $('#pills-home').removeClass('show');
    $('#pills-profile').addClass('show');
  }
  LatestMoreClick() {
    this.spinner.show();
    this.service.getlatestpagetopics(this.pageNo)
      .subscribe((result: any) => {
        result.topic_list.topics.forEach(e => {
          this.topicsData.push(e);
        })

        this.topicsData.forEach(e => {
          this.categData.forEach(opt => {
            if (e.category_id == opt.id) {
              e.category_name = opt.name;
              e.background_colour = '#' + opt.color;
              e.text_color = '#' + opt.text_color;
            }
          })
        });
        if (result.topic_list.topics.length < 30) {
          this.isLatestBtn = false;
          this.spinner.hide();
        }
        else {
          this.isLatestBtn = true;
          this.pageNo = this.pageNo + 1;
          this.spinner.hide();
        }
      });
  }
  getCatgTopics(catgid, catgname) {
    this.catgtopicsdata = [];
    this.selcatgid = catgid;
    this.catgtype = catgname;
    this.service.getcategorytopics(catgid)
      .subscribe((result: any) => {
        this.catgtopicsdata = result.topic_list.topics;
        this.catgtopicsdata.forEach(e => {
          this.categData.forEach(opt => {
            if (e.category_id == opt.id) {
              e.category_name = opt.name;
              e.background_colour = '#' + opt.color;
              //e.text_color = '#' + opt.text_color;
            }
          })
        });

      });
    window.scroll(0, 100);
    this.showcat = false;
  }
}
export class dashOpp {
  topictitle: string;
  topicbody: string;
  catId: number;
  topicId: number;
  txtReplyDesc: string;
}
