import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { registrationService } from '../common/services/registration.service';
import { Router, Data, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HttpClientModule, HttpClient, HttpEventType } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { CONFIGURATION } from './../app.constants';
import { seoservice } from './../common/services/seo.service';
declare var $: any;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})

export class RegistrationComponent implements OnInit {
  url: any;
  constructor(private service: registrationService, private router: Router, private formBuilder: FormBuilder, private http: HttpClient, private cookieService: CookieService, private activatedRoute: ActivatedRoute, private SeoService: seoservice) {
    this.url = CONFIGURATION.baseUrls.ServerPath;
  }
  registerForm: FormGroup;
  forgotForm: FormGroup;
  loading = false;
  submitted = false;
  alertService: any = [];
  userService: any = [];
  regis: reg[];
  countrystateList: any = [];
  countryList: any = [];
  stateList: any[] = [];
  ethinicityList: any = [];
  year: any = [];
  languageSelected: any = [];
  selectedLangCode: any = [];
  yearLst: any = [];
  countryid:any;
  currentYear: any;
  selectedCountry: any;
  isInvalidEmail: boolean = false;
  orgDetails: any = [];
  forgotUserData: any;
  title: any = [];
  public progress: number;
  message: string;
  mode: any;
  ismode: boolean = false;
  profilelist: any = [];
  editEmail: any = '';
  txtRegister: any;
  txtsave: any;
  failure: boolean = false;
  isSuccess: boolean = false;
  isReadOnly: boolean;
  isUploadSuccess: boolean = false;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  disError: any;
  isDisError: boolean = false;
  @Output() public onUploadFinished = new EventEmitter();
  image: any;
  fileName: any;
  emailchk: any;
  emailexists: any;
  userExists: any;
  userChk: any;
  termsCheck: boolean = false;
  isReceiveSms: boolean = false;
  termsError: boolean = false;
  recaptchaError: boolean = false;
  leadid: string = "";
  pc: string = "";
  extid: string = "";
  rid: number;
  sid: string="";
  txid: string="";
  trans_id: string="";
  isLeadserExists: boolean = false;
  get f() {
    return this.registerForm.controls;
  }

  ngOnInit() {
    this.SeoService.setPageMetaData('Register | The most rewarding business community - Conversant.pro', 'Connect with peers, business opportunities and industry news while earning rewards for participation in the community and business surveys.');
    this.SeoService.addFacebookMetaTag();
    this.activatedRoute.queryParams.subscribe(params => {
      this.leadid = params['leadid'];
      this.pc = params['pc'];
      this.rid = parseInt(params['rid']);
      this.sid = params['sid'];
      this.txid = params['txid'];
      this.trans_id = params['trans_id'];
      this.extid = params['extid'];
      if (typeof params['leadid'] != 'undefined') {
        this.facebooktrackingScript();
      }
    });
    this.GetCountrysAndStates();
    this.GetEthinicity();
    this.bindingYears();
    if (this.rid != undefined && this.txid != undefined && (this.leadid == undefined || this.leadid == '')) {
      this.clickins();
    }

    this.mode = localStorage.getItem('mode');
    if (this.mode == 'regEdt') {
      this.txtsave = "Save"
      this.txtRegister = "Edit Profile"
      this.ismode = true;
      this.isReadOnly = true;

    }
    else {
      this.txtsave = "Register"
      this.txtRegister = "Register"
      this.isReadOnly = false;
      this.ismode = false;
      this.editEmail = localStorage.getItem('email');

    }



    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      address1: ['', Validators.required],
      address2: [''],
      country: ['', Validators.required],
      stateProvince: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', Validators.required],
      mobilephone: [''],
      gender: ['', Validators.required],
      month: ['', Validators.required],
      date: ['', Validators.required],
      year: ['', Validators.required],
      ethnicity: ['', Validators.required],
      employer: ['', Validators.required],
      jobTitle: ['', Validators.required],

      password: ['', [Validators.required, Validators.minLength(8)]]
    });



  }
  ngAfterViewInit() {
    if (this.editEmail != '') {
      this.registerForm.controls['email'].setValue(this.editEmail);
      localStorage.setItem('email', '');
    }
  }
  onSubmit() {
    this.isLeadserExists = false;
    this.recaptchaError = false;
    this.submitted = true;
    this.disError = '';
    this.isDisError = false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    //check subid validations
    if (this.leadid !=undefined && this.leadid.length > 0) {
      let ouserdata: userdata = new userdata();
      ouserdata.EmailAddress = this.registerForm.value.email,
        ouserdata.SubId3 = this.leadid;
      this.service.GetCheckSubid(ouserdata)
        .subscribe((result: any) => {
          if (result.cpaCount == 0) {
            this.isLeadserExists = false;
            $("#lblSuccess").css("display", "inline");
            this.userUpdate();
          }
          else {
            this.isLeadserExists = true;
          }
        });
    }
    else {
      this.userUpdate();
    }
  }
  userUpdate() {
    if ($('#g-recaptcha-response').val() != "") {
      let oreg: reg = new reg();
      oreg.FirstName = this.registerForm.value.firstName,
        oreg.LastName = this.registerForm.value.lastName,
        oreg.UserName = this.registerForm.value.username,
        oreg.Address1 = this.registerForm.value.address1
      if (this.registerForm.value.address2) {
        oreg.Address2 = this.registerForm.value.address2
      }
      oreg.CountryId = parseInt(this.registerForm.value.country),
        oreg.StateId = parseInt(this.registerForm.value.stateProvince),
        oreg.City = this.registerForm.value.city,
        oreg.ZipCode = this.registerForm.value.zipCode
      if (this.registerForm.value.mobilephone) {
        oreg.PhoneNumber = this.registerForm.value.mobilephone.toString()
      }
      oreg.Gender = this.registerForm.value.gender,
        oreg.Dob = this.registerForm.value.month + '-' + this.registerForm.value.date + '-' + this.registerForm.value.year,
        oreg.EthnicityId = parseInt(this.registerForm.value.ethnicity),
        oreg.Password = this.registerForm.value.password,
        oreg.EmailAddress = this.registerForm.value.email,
        oreg.Employer = this.registerForm.value.employer,
        oreg.JobTitle = this.registerForm.value.jobTitle,
        this.selectedCountry = this.countryList.filter(e => e.countryId == parseInt(this.registerForm.value.country));
      oreg.CountryCode = this.selectedCountry[0].countryCode;
      oreg.IsReceiveSms = this.isReceiveSms;
      oreg.SubId3 = this.leadid;
      //if (this.rid != undefined && this.txid != undefined && (this.leadid == undefined || this.leadid == '')) {
      //  if (this.leadid != undefined) {
      //    oreg.SubId3 = this.leadid;
      //  }
      //  else if (this.txid != undefined) {
      //    oreg.SubId3 = this.txid;
      //  }
      //  if (this.leadid != undefined) {
      //    oreg.SubId3 = this.leadid;
      //  }
      //  else if (this.trans_id != undefined) {
      //    oreg.SubId3 = this.trans_id;
      //  }
      //  oreg.SubId2 = this.sid;
      //  oreg.RefferId = this.rid;
      //  oreg.CreatedBy = "Affiliate";
      //}
      this.loading = true;
      if (this.mode == 'regEdt') {
        this.service.UserUpdate(oreg)
          .subscribe((result: reg) => {
            this.isSuccess = true;
            $("#lblSuccess").css("display", "inline");
            this.loading = false;
            // window.location.href = '/login';
          });
      }
      else {
        this.EmailCheck(oreg);

      }
    }
    else {
      this.recaptchaError = true;
    }
  }
  public facebooktrackingScript() {
    var imgfbtrack = document.createElement('img');
    imgfbtrack.height = 1;
    imgfbtrack.width = 1;
    imgfbtrack.alt = "";
    imgfbtrack.src = "https://www.facebook.com/tr?id=124147289922780&ev=PageView&noscript=1";
    document.getElementsByTagName('head')[0].appendChild(imgfbtrack);
  }
  GetCountrysAndStates() {
    this.service.GetCountrysAndStates()
      .subscribe((result) => {
        this.countrystateList = result;
        this.countryList = this.countrystateList.countryList;
        if (this.mode == 'regEdt') {
          this.JsonUser();
        }
        else {
          if (this.leadid != undefined && this.leadid != "")
            this.JsonUserInfo();
        }
      });
  }
  countryChange(val) {
    this.countryid = val;
    this.stateList = [];
    if (val && (this.countrystateList.stateList.length > 0)) {
      this.stateList = this.countrystateList.stateList.filter(e => e.countryId == val)
    }

  }

  GetEthinicity() {
    this.service.GetEthinicity()
      .subscribe((result) => {
        this.ethinicityList = result;
      });
  }

  EmailCheck(oreg) {
    this.emailchk = '';
    if (this.registerForm.controls['email'].value != '') {
      this.service.GetEmailCheck(this.registerForm.controls['email'].value)
        .subscribe((result) => {
          this.emailexists = result;
          // this.emailchk = 'Email Already Exists'
          if (this.emailexists.cpaCount != 0) {
            this.emailchk = 'Email Address Already Exists!';
            this.loading = false;
          }
          else {
            // this.emailchk = 'Email Address Available';
            //this.service.RegistrationSave(oreg)
            //  .subscribe((result: reg) => {
            //    window.location.href = '/login';
            //  });
            this.UserNameCheck(oreg)
          }
        });
    } else {
      this.emailchk = '';
    }
  }
  UserNameCheck(oreg) {
    this.userChk = '';
    if (this.registerForm.controls['username'].value != '') {
      this.service.GetUserCheck(this.registerForm.controls['username'].value)
        .subscribe((result) => {
          this.userExists = result;
          if (this.userExists.userCount != 0) {
            this.userChk = 'UserName Already Exists!';
            this.loading = false;
          }
          else {
            if (this.termsCheck == false) {
              this.termsError = true;
              this.loading = false;

            }
            else {
              this.termsError = false;
              if (this.rid != undefined && this.txid != undefined && (this.leadid == undefined || this.leadid == '')) {
                if (this.leadid != undefined) {
                  oreg.SubId3 = this.leadid;
                }
                else if (this.txid != undefined) {
                  oreg.SubId3 = this.txid;
                }
                if (this.leadid != undefined) {
                  oreg.SubId3 = this.leadid;
                }
                else if (this.trans_id != undefined) {
                  oreg.SubId3 = this.trans_id;
                }
                oreg.SubId2 = this.sid;
                oreg.RefferId = this.rid;
                oreg.CreatedBy = "Affiliate";
              }

              this.service.RegistrationSave(oreg)
                .subscribe((result: any) => {
                  if (result == "Success") {
                    if (this.leadid != undefined && this.leadid != "") {
                      //window.location.href = '/dashboard?leadguid=' + this.leadid;
                      //window.location.href = '/dashboard?leadid=' + this.leadid + '&pc=' + this.pc;
                      window.location.href = '/dashboard?leadid=' + this.leadid + '&pc=' + this.pc + '&extid=' + this.extid;
                    }
                    else {
                      window.location.href = '/dashboard';
                    }
                  }
                  else {
                    this.isDisError = true;
                    this.disError = result;
                    this.loading = false;
                  }
                });
            }

          }
        });
    } else {
      this.userChk = '';
    }
  }


  bindingYears() {
    //beind years
    var d = new Date();
    this.currentYear = d.getFullYear();
    for (var i = 13; i < 100; i++) {
      this.yearLst.push({ key: this.currentYear - i, value: this.currentYear - i });
    }
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;
    var splitFilename = this.fileData.name.split('.')[1];
    if (splitFilename == 'png' || splitFilename == 'jpg' || splitFilename == 'jpeg') {
      this.failure = false;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
        this.previewUrl = reader.result;
      }
    }
    else {
      this.failure = true;
      this.previewUrl = null;
    }
  }

  onSubmit1() {
    const formData = new FormData();
    formData.append('file', this.fileData);
    this.http.post(this.url + 'Registration/Upload', formData, { responseType: 'text' })
      .subscribe(res => {
        this.uploadedFilePath = res;
        this.isUploadSuccess = true;
        $("#lblUploadSuccess").css("display", "inline");
        //  this.message = 'Upload success.';
      })
  }
  newwindow(id) {
    var url = "https://wl.conversant.pro";
    if (id == 1) {
      url = url + "/WidgetTerms.html";
    }
    else if (id == 2) {
      url = url + "/login/p";
    }
    else if (id == 3) {
      url = url + "/login/cu";
    }
    else if (id == 4) {
      url = "cookiepolicy";
    }
    else if (id == 5) {
      url = url + "/login/dns";
    }
    else if (id == 6) {
      url = "aboutus";
    }

    //var w = window.open(url, '_blank', 'height=500,width=500');
    var iMyWidth;
    var iMyHeight;
    //half the screen width minus half the new window width (plus 5 pixel borders).
    iMyWidth = (window.screen.width / 2) - (400);
    //half the screen height minus half the new window height (plus title and status bars).
    iMyHeight = (window.screen.height / 2) - (225);
    //Open the window.
    var win2 = window.open(url, "_blank", "status=no,height=450,width=800,resizable=yes,left=" + iMyWidth + ",top=" + iMyHeight + ",screenX=" + iMyWidth + ",screenY=" + iMyHeight + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    win2.focus();
  }

  //public uploadFile = (files) => {
  //  if (files.length === 0) {
  //    return;
  //  }
  //  let fileToUpload = <File>files[0];
  //  const formData = new FormData();
  //  formData.append('file', fileToUpload, fileToUpload.name);

  //  this.http.post('https://localhost:44360/Registration/Upload', formData, { reportProgress: true, observe: 'events' })
  //    .subscribe(event => {
  //      if (event.type === HttpEventType.UploadProgress)
  //        this.progress = Math.round(100 * event.loaded / event.total);
  //      else if (event.type === HttpEventType.Response) {
  //        this.message = 'Upload success.';
  //      //  this.onUploadFinished.emit(event.body);

  //      }
  //    });
  //}

  JsonUser() {
    this.service.GetUserProfile()
      .subscribe((result) => {
        this.profilelist = result;
        this.editEmail = this.profilelist.emailAddress;
        this.countryChange(this.profilelist.countryId);
        //this.registerForm.value.firstName.setValue(this.profilelist.firstName);
        this.registerForm.controls['firstName'].setValue(this.profilelist.firstName);
        this.registerForm.controls['lastName'].setValue(this.profilelist.lastName);
        this.registerForm.controls['username'].setValue(this.profilelist.userName);
        this.registerForm.controls['address1'].setValue(this.profilelist.address1);
        this.registerForm.controls['address2'].setValue(this.profilelist.address2);
        this.registerForm.controls['country'].setValue(this.profilelist.countryId);
        this.registerForm.controls['stateProvince'].setValue(this.profilelist.stateId);
        this.registerForm.controls['city'].setValue(this.profilelist.city);
        this.registerForm.controls['zipCode'].setValue(this.profilelist.zipCode);
        this.registerForm.controls['gender'].setValue(this.profilelist.gender);
        this.registerForm.controls['ethnicity'].setValue(this.profilelist.ethnicityId);
        this.registerForm.controls['mobilephone'].setValue(this.profilelist.phoneNumber);
        this.registerForm.controls['email'].setValue(this.profilelist.emailAddress);
        this.registerForm.controls['password'].setValue(this.profilelist.password);
        this.registerForm.controls['date'].setValue(this.profilelist.day);
        this.registerForm.controls['month'].setValue(this.profilelist.month);
        this.registerForm.controls['year'].setValue(this.profilelist.year);
        this.registerForm.controls['employer'].setValue(this.profilelist.employer);
        this.registerForm.controls['jobTitle'].setValue(this.profilelist.jobTitle);
        this.uploadedFilePath = this.profilelist.facebook_user_logo,
          this.isReceiveSms = this.profilelist.isReceiveSms,
          this.previewUrl = "/profileuploads/" + this.profilelist.facebook_user_logo + "?" + Math.floor(Math.random() * (999999 - 100000)) + 100000;
      });
  }
  JsonUserInfo() {
    this.service.GetUserInfo(this.leadid)
      .subscribe((result) => {
        this.profilelist = result;
        this.registerForm.controls['firstName'].setValue(this.profilelist.firstName);
        this.registerForm.controls['lastName'].setValue(this.profilelist.lastName);
        this.registerForm.controls['email'].setValue(this.profilelist.emailAddress);
        if (this.profilelist.gender !== null) {
          this.registerForm.controls['gender'].setValue(this.profilelist.gender);
        }
        this.registerForm.controls['country'].setValue(this.profilelist.countryId);
        this.countryChange(this.profilelist.countryId);
        this.registerForm.controls['ethnicity'].setValue(this.profilelist.ethnicityId);
        this.registerForm.controls['city'].setValue(this.profilelist.city);
        this.registerForm.controls['zipCode'].setValue(this.profilelist.zipCode);
        this.registerForm.controls['address1'].setValue(this.profilelist.address1);
        this.registerForm.controls['mobilephone'].setValue(this.profilelist.phoneNumber);
        if (this.profilelist.day !== null) {
          this.registerForm.controls['date'].setValue(this.profilelist.day);
        }
        if (this.profilelist.month !== null) {
          this.registerForm.controls['month'].setValue(this.profilelist.month);
        }
        if (this.profilelist.year !== null) {
          this.registerForm.controls['year'].setValue(this.profilelist.year);
        }

      });
  }
  clickins() {
    debugger;
    let oclickdata: clickdata = new clickdata();
    oclickdata.rid = this.rid;
    oclickdata.sid = this.sid;
    oclickdata.txid = this.txid;
    oclickdata.trans_id = this.trans_id;
    this.service.ClikInsert(oclickdata)
      .subscribe((result) => {
        //alert(result);

      });
  }

  onCancel() {
    if (this.mode == 'regEdt') {
      window.location.href = '/dashboard';
    }
    else {
      window.location.href = '/login';
    }

  }
  deleteConfirm() {
    $('#delModel').modal('show');
  }
  deleteAcc() {
    $('#delModel').modal('hide');
    this.service.DeleteAccount()
      .subscribe((result) => {
        alert("Sorry to see you go");
        window.location.href = '/login';
      });

  }
  valuechange($event) {
    var reqchars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_.-";
    var pos = reqchars.indexOf($event.key);
    var isValid = true;
    if (pos ==-1) {
      isValid = false;
    }
    return isValid;
  }
}

export class userdata {
  EmailAddress: string;
  SubId3: string;
}
export class clickdata {
  rid: number;
  sid: string;
  txid: string;
  trans_id: string;
}
export class reg {
  UserId: number;
  OrgId: number;
  EmailAddress: string;
  Password: string;
  CountryCode: string;
  CountryName: string;
  StateCode: string;
  StateName: string;
  UserGuid: string;
  FirstName: string;
  LastName: string;
  Address1: string;
  Address2: string;
  CountryId: number;
  City: string;
  ZipCode: string;
  PhoneNumber: string;
  Dob: string;
  EthnicityId: number;
  Gender: string;
  StateId: number;
  CreatedBy: string;
  UpdatedBy: string;
  IsFraud: boolean;
  UserInvitationGuid: string;
  RefferId: number;
  UserName: string;
  PostalCode: string;
  SubId3: string;
  DomainUrl: string;
  SubId2: string;
  IpAddress: string;
  Employer: string;
  JobTitle: string;
  static data: any;
  IsReceiveSms: boolean;
}
