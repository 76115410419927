import { Component, OnInit } from '@angular/core';
import { seoservice } from './../common/services/seo.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private SeoService: seoservice) { }

  ngOnInit() {
    this.SeoService.setPageMetaData('FAQ | The most rewarding business community - Conversant.pro', 'Connect with peers, business opportunities and industry news while earning rewards for participation in the community and business surveys.');
  }

}
