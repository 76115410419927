import { Component, OnInit, Input } from '@angular/core';
import { VariablesService } from '../common/services/variables.service';
import { newsfeedService } from './../common/services/newsfeed.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private service: VariablesService, private nfservice: newsfeedService, private formBuilder: FormBuilder, private router: Router, private cookieService: CookieService) { }

  show: any;
  shownewsfeed: any;
  current: any;
  newsfeedList: any[] = [];
  newsfeedSelectedList: any;
  title: any;
  message: any;
  emailid: any;
  emailForm: FormGroup;
  submitted = false;
  showemail = true;
  currentYear = new Date().getFullYear();

  ngOnInit() {
    this.current = this.service.current_url.split('/')[1];
    if (this.current == '' || this.current == undefined) {
      this.show = true;
      this.showemail = true;
      this.shownewsfeed = false;
    }
    else if (this.current == 'login' || this.current == 'newsfeed') {
      this.show = false;
      this.showemail = true;
      this.shownewsfeed = false;
    }
    else {
      this.shownewsfeed = false;
      this.show = false;
      //this.getNewsfeed();
      var token = this.cookieService.get('token')
      if (token) {
        this.showemail = false;
      }
    }
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  getNewsfeed() {
    this.nfservice.GetNewsfeed()
      .subscribe((result: any) => {
        this.newsfeedList = result;
      });
  };

  select(id) {
    this.nfservice.SelectNewsfeed(id)
      .subscribe((result: any) => {
        this.newsfeedSelectedList = result;
        this.title = this.newsfeedSelectedList[0].newsfeedTitle;
        this.message = this.newsfeedSelectedList[0].newsfeedDesc;
      });
  };

  close() {
    this.title = "";
    this.message = "";
  };

  get f() {
    return this.emailForm.controls;
  };

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.emailForm.invalid) {
      return;
    }
    else {
      localStorage.setItem('email', this.emailid);
      //this.router.navigate(['/registration', 0, this.emailid]);
      window.location.href = '/registration';
    }
    //this.emailForm.reset();
  };
  checkmenu(menuid) {
    if (menuid == 6) {
        window.location.href = '/faq';
      }
  }
  newwindow(id) {
    var url = "https://wl.conversant.pro";
    if (id == 1) {
      url = url + "/WidgetTerms.html";
    }
    else if (id == 2) {
      url = url + "/login/p";
    }
    else if (id == 3) {
      url = url + "/login/cu";
    }
    else if (id == 4) {
      url = "cookiepolicy";
    }
    else if (id == 5) {
      url = url + "/login/dns";
    }
    else if (id == 6) {
      url = "faq";
    }

    //var w = window.open(url, '_blank', 'height=500,width=500');
    var iMyWidth;
    var iMyHeight;
    var left = (screen.width - 900) / 2;
    var top = (screen.height - 550) / 4;
    //half the screen width minus half the new window width (plus 5 pixel borders).
    //iMyWidth = (window.screen.width / 2) - (400);
    //half the screen height minus half the new window height (plus title and status bars).
    //iMyHeight = (window.screen.height / 2) - (225);
    //Open the window.
    //var win2 = window.open(url, "_blank", "status=no,height=450,width=800,resizable=yes,left=" + iMyWidth + ",top=" + iMyHeight + ",screenX=" + iMyWidth + ",screenY=" + iMyHeight + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    var win2 = window.open(url, "_blank", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=900, height=550, top=' + top + ', left=' + left);
    win2.focus();
  }

  opprClick(catgId, filter) {
    let myObj = { oppCatId: catgId, filter: filter };
    localStorage.setItem("footerObject", JSON.stringify(myObj));
    window.location.href = '/opportunities';
  }
  catgClick(catgId, filter) {
    let myObj = { topicCatgId: catgId, filter: filter };
    localStorage.setItem("catgsearch", JSON.stringify(myObj));
    window.location.href = '/circles';
  }
  surveyClick(catgId, filter) {
    let myObj = { surveyCatgId: catgId, filter: filter };
    window.location.href = '/login';
  }
}
