import { Component, OnInit } from '@angular/core';
import { opportunitiesService } from './../common/services/opportunities.service'
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Resp, CreateOpp } from '../opportunities/opportunities.component';

declare var $: any;
@Component({
  selector: 'app-opportunities',
  templateUrl: './useropportunities.component.html',
  styleUrls: ['./opportunities.component.css']
})
export class UserOpportunitiesComponent implements OnInit {
  constructor(private service: opportunitiesService, private router: Router, private formBuilder: FormBuilder, private cookieService: CookieService) { }
  oppDealsList: any[] = [];
  jobList: any[] = [];
  mentorsList: any[] = [];
  respId: any;
  oppId: any;
  delType: any;
  usertype: any;
  userTopics: any[] = [];
  topid: any;
  noTopicsRecords: boolean = false;
  noDealsRecords: boolean = false;
  noJobsRecords: boolean = false;
  noMentorsRecords: boolean = false;
  modalForm1: FormGroup;
  Opportunities: any;
  submitted = false;
  editesoppId: any;
  ngOnInit() {
    this.getUseropportunities();
    this.modalForm1 = this.formBuilder.group({
      title: ['', [Validators.required]],
      message: ['', Validators.required],

    });
    this.delType = "Opportunity";
    this.Discusercreatedtopics();
  }
  getUseropportunities() {
    this.oppDealsList = [];
    this.jobList = [];
    this.mentorsList = [];
    this.service.GetUserOpportunities()
      .subscribe((result: any) => {
        result.forEach(i => {
          if (i.opportunityCatgid == 1) {
            this.oppDealsList.push(i);
          }
          else if (i.opportunityCatgid == 2) {
            this.jobList.push(i);
          }
          else if (i.opportunityCatgid == 3) {
            this.mentorsList.push(i);
          }
        });
        if (this.oppDealsList.length == 0) {
          this.noDealsRecords = true;
        }
        else {
          this.noDealsRecords = false;
        }
        if (this.jobList.length == 0) {
          this.noJobsRecords = true;
        }
        else {
          this.noJobsRecords = false;
        }
        if (this.mentorsList.length == 0) {
          this.noMentorsRecords = true;
        }
        else {
          this.noMentorsRecords = false;
        }
      });
  }
  btnResDelete(id) {
    this.respId = id;
    $('#delResModel').modal('show');
  }
  deleteRes() {
    this.service.OpportunitiesResponseDelete(this.respId)
      .subscribe((result: any) => {
        $('#delResModel').modal('hide');
        this.getUseropportunities();
      });
  }
  btnOppDlt(oppId, typeid) {
    this.oppId = oppId;
    if (typeid == 0) {
      this.delType = "Topic";
    }
    else if (typeid == 1) {
      this.delType = "Deal";
    }
    else if (typeid == 2) {
      this.delType = "Job";
    }
    else if (typeid == 3) {
      this.delType = "Mentor";
    }
    $('#delOppModel').modal('show');
  }
  deleteOpp() {
    $('#delOppModel').modal('hide');
    this.service.OpportunitiesDelete(this.oppId)
      .subscribe((result: any) => {
        alert("Opportunity deleted successfully.")
        this.getUseropportunities();
      });
  }

  Discusercreatedtopics() {
    this.userTopics = [];
    this.service.GetDiscUserTopics()
      .subscribe((result: any) => {
        this.userTopics = result.user_actions.filter(e => e.post_number == 1);
        if (this.userTopics.length == 0) {
          this.noTopicsRecords = true;
        }
        else {
          this.noTopicsRecords = false;
        }
      });
  }

  btnDiscdeltopic(topic_id) {
    this.topid = topic_id;
    $('#delTopicModel').modal('show');
  }


  Discdeltopic() {
    $('#delTopicModel').modal('hide');
    this.service.DelDiscTopics(this.topid)
      .subscribe((result: any) => {
        alert("Topic deleted successfully.")
        this.Discusercreatedtopics();
      });

  }
  btnEdit(opprId, catId) {
    this.editesoppId = opprId;
    if (catId == 1) {
      this.Opportunities = "Edit Deal";
    }
    else if (catId == 2) {
      this.Opportunities = "Edit Job";
    }
    else if (catId == 3) {
      this.Opportunities = "Edit Mentor";
    }
    this.service.oppById(opprId)
      .subscribe((result: any) => {
        this.modalForm1.controls['title'].setValue(result.title);
        this.modalForm1.controls['message'].setValue(result.desc);
      });

  }

  UpdateOpp() {
    if (this.modalForm1.invalid) {
      this.submitted = true;
      return;
    }
    else {
      this.UpdateOppDetails();
    }
  }
  UpdateOppDetails() {
    let ocreateopp: CreateOpp = new CreateOpp();
    //ocreateopp.cat_id = this.catId;
    ocreateopp.title = this.modalForm1.value.title;
    ocreateopp.desc = this.modalForm1.value.message;
    ocreateopp.oppr_id = this.editesoppId;
    this.service.OpportunitiesUpdate(ocreateopp).subscribe((result: any) => {
      $('#updateModal').modal('hide');
      this.getUseropportunities();
      this.modalForm1.reset();
    });
  }
  get f() {
    return this.modalForm1.controls;
  }
  onSubmit() {
  }
}

