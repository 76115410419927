import { Component, OnInit } from '@angular/core';
import { VariablesService } from '../common/services/variables.service';

import { AuthService } from 'angularx-social-login';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { surveysService } from './../common/services/surveys.service';
import { seoservice } from './../common/services/seo.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private service: VariablesService, private authService: AuthService, private router: Router, private cookieService: CookieService, private surservice: surveysService, private SeoService: seoservice) { }
  token: any;
  show_signin: boolean = false;
  show_account: boolean = false;
  toggle: boolean = false;
  current: any;
  email: any;
  userName: any;
  ShowNewsfeed: boolean = false;
  showntfnunread: boolean = false;
  showntfnpvtmsg: boolean = false;
  notification: any;
  ntfnunreadcount: any;
  ntfnpvtmsg: any;
  ntfnunreadpvtmsg: any;
  ulastname: any;
  ntfnurl: any;
  data: any;
  notificationdata: any;
  totalpoints: any;
  discnotifications: any;
  dwidth: any; height: any;
  noNtfnRecords: boolean = false;
  isadmin: boolean = false;

  ngOnInit() {
    this.SeoService.createCanonicalURL();
    //this.token = this.cookieService.get('token');
    this.service.userSessionData().subscribe((result: any) => {
      if (result != 0) {
        this.show_account = true;
        this.show_signin = false;
      }
      else {
       this.show_signin = true;
        this.show_account = false;
      }
      if (this.show_account == true) {
        this.getRewardPoints();
        this.getnotifications();
        this.getadminUserFlag();
      }
    });
    //this.current = this.service.current_url.split('/')[1];
    //if (this.current == '' || this.current == 'login' || this.current == undefined) {
    //  this.show_account = false;
    //  this.show_signin = true;
    //}
    this.responsiveht();

  }
  //onscreensize changes this event will fire
  onResize(event) {
    this.responsiveht();
  }
  //get menu height before and after login
  responsiveht() {
    if (this.show_signin == true) {
      this.dwidth = (window.screen.width);
      if (this.dwidth <= 760) {
        this.height = '280px';
      }
      else {
        this.height = '';
      }
    }

    if (this.show_signin === false) {
      this.dwidth = (window.screen.width);
      if (this.dwidth >= 760) {
        this.height = '';
      }
      else {
        this.height = '640px';
      }
    }
  }
  //----
  clickEvent(event) {
    //if you just want to toggle the class; change toggle variable.
    this.toggle = !this.toggle;
  }

  highlightItem(event) {
    if (!event.target.classList.contains('active')) {
      event.target.classList.add('active');
    }
  }

  getnotifications() {
    this.service.DiscourseNotifications().subscribe((result: any) => {
      this.notification = result;
      if (this.notification) {
        this.ntfnunreadcount = this.notification.unread_notifications;
        this.ntfnpvtmsg = this.notification.unread_private_messages;
      }

      //this.ntfnunreadcount = 10;
      if (this.ntfnunreadcount > 0) {
        this.showntfnunread = true;
      }
      if (this.ntfnpvtmsg > 0) {
        this.showntfnpvtmsg = true;
      }
    });
    this.service.DiscourseNotificationsdata().subscribe((result: any) => {
      this.notificationdata = result;
      if (this.notificationdata) {
        this.discnotifications = this.notificationdata.notifications;
      }
      if (this.discnotifications.length) {
        this.noNtfnRecords = false;
      } else {
        this.noNtfnRecords = true;
      }
    });
  }
  getSessionData() {
    this.service.userSessionData()
      .subscribe((result: any) => {
        if (result != 0) {
          this.show_account = true;
          this.show_signin = false;
        }

      });
  }
  getRewardPoints() {
    this.surservice.GetRewardPointsList()
      .subscribe((result: any) => {
        if (result) {
          this.totalpoints = result.totalPoints;
        }
        else {
          this.totalpoints = 0;
        }
      });
  }

  logout() {
    this.service.DiscourseLogout()
      .subscribe((result: any) => {
      });
    this.service.DiscourseLoginreqFalse()
      .subscribe((result: any) => {
      });

    this.authService.signOut().then(data => {
      //this.router.navigate(['login']);
    });
    localStorage.setItem('mode', "");
    this.service.tokenEmpty()
      .subscribe((result: any) => {
        //this.router.navigate([result]);
        this.cookieService.deleteAll();
        window.location.href = '';
      });
  }
  accountclick() {
    localStorage.setItem('mode', 'regEdt');
    window.location.href = '/registration';

  }
  accOutlineClick() {
    window.location.href = '/useropportunities';
  }

  checklogin() {
    if (this.show_account)
    {
      window.location.href = '/dashboard';
    }
    else
    {
      window.location.href = "";
    }
  }
  checkmenu(menuid) {
    if (this.show_account) {
      if (menuid == 1) {
        window.location.href = '/dashboard';
      }
      else if (menuid == 2) {
        window.location.href = '/opportunities';
      }
      else if (menuid == 3) {
        window.location.href = '/surveys';
      }
      else if (menuid == 4) {
        window.location.href = '/rewards';
      }
      else if (menuid == 5) {
        window.location.href = '/aboutus';
      }
      else if (menuid == 6) {
        window.location.href = '/faq';
      }
      else {
        window.location.href = '/dashboard';
      }
    }
    else {
      if (menuid == 1) {
        window.location.href = '/circles';
      }
      else if (menuid == 2) {
        window.location.href = '/opportunities';
      }
      else if (menuid == 5) {
        window.location.href = '/aboutus';
      }
      else if (menuid == 6) {
        window.location.href = '/faq';
      }
      else {
        window.location.href = "/login";
      }
    }
  }
  getadminUserFlag() {
    this.service.getadminUserFlag()
      .subscribe((result: any) => {
        if (result == "true") {
          this.isadmin = true;
        }
        else {
          this.isadmin = false;
        }
      });
  }
  newwindow(id) {
    var url = "https://wl.conversant.pro";
    if (id == 1) {
      url = url + "/WidgetTerms.html";
    }
    else if (id == 2) {
      url = url + "/login/p";
    }
    else if (id == 3) {
      url = url + "/login/cu";
    }
    else if (id == 4) {
      url = "cookiepolicy";
    }
    else if (id == 5) {
      url = url + "/login/dns";
    }
    else if (id == 6) {
      url = "aboutus";
    }
    else if (id == 7) {
      url = "faq";
    }

    //var w = window.open(url, '_blank', 'height=500,width=500');
    var iMyWidth;
    var iMyHeight;
    var left = (screen.width - 900) / 2;
    var top = (screen.height - 550) / 4;
    //half the screen width minus half the new window width (plus 5 pixel borders).
    //iMyWidth = (window.screen.width / 2) - (400);
    //half the screen height minus half the new window height (plus title and status bars).
    //iMyHeight = (window.screen.height / 2) - (225);
    //Open the window.
    //var win2 = window.open(url, "_blank", "status=no,height=450,width=800,resizable=yes,left=" + iMyWidth + ",top=" + iMyHeight + ",screenX=" + iMyWidth + ",screenY=" + iMyHeight + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    var win2 = window.open(url, "_blank", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=900, height=550, top=' + top + ', left=' + left);
    win2.focus();
  }
  conversation(id, title) {
    this.toggle = false;
    this.current = this.service.current_url.split('/')[1];
    localStorage.setItem("ntfnpage", "headerpage")
    localStorage.setItem("ntftopid", id);
    localStorage.setItem("ntftoptitle", title);
    if (this.current == '') {
      //window.scroll(0, 0);
    } else {
      window.location.href = '/dashboard';
      window.scroll(0, 500);
    }

  }
  conversationmobile(tid, ttitle) {
    $('#ntfn').modal('hide');
    this.toggle = false;
    this.current = this.service.current_url.split('/')[1];
    localStorage.setItem("ntfnpage", "headerpage")
    localStorage.setItem("ntftopid", tid);
    localStorage.setItem("ntftoptitle", ttitle);
    if (this.current == '') {
      //window.scroll(0, 500);
    } else {
      window.location.href = '/dashboard';
      window.scroll(0, 500);
    }

  }


}
