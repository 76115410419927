import { Component, OnInit, ElementRef } from '@angular/core';
import { VariablesService } from './../common/services/variables.service';
import { surveysService } from './../common/services/surveys.service';
import { opportunitiesService } from './../common/services/opportunities.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from 'ngx-cookie-service';
import { FormControlName } from '@angular/forms';
import { Location } from '@angular/common';
import { seoservice } from './../common/services/seo.service';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']

})
export class DashboardComponent implements OnInit {

  constructor(private service: VariablesService, private surservice: surveysService, private oppservice: opportunitiesService, private router: Router, private elRef: ElementRef, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private cookieService: CookieService, private route: ActivatedRoute, private location: Location, private SeoService: seoservice) { }
  token: any;
  createtopicForm: FormGroup;
  updatetopicForm: FormGroup;
  updateresForm: FormGroup;
  submitted = false;
  submitted3 = false;
  postslikes: any;//[] = [];
  userName: any;
  firstName: any;
  lastName: any;
  surveyList: any[] = [];
  OppList: any[] = [];
  noRecords: boolean = false;
  noOppRecords: boolean = false;
  surveysCount: number;
  profilepic: any;
  facebookid: any;
  userlogo: any;
  reachedpoints: any;
  targetpoints: any;
  totalpoints: any;
  pendingpoints: any;
  rewardpercentage: any;
  topicsData: any[] = [];
  categData: any[] = [];
  topicResponce: any[] = [];
  isTopics: boolean = true;
  titlecatgname: any;
  catgcolor: any;
  topicTitle: any;
  topicDetails: any;
  topicId: any;
  txtReplyDesc: any;
  txtTopicTitle: any;
  txtTopicBody: any;
  ddlCatId: any;
  suggestedtopics: any;
  topiclike: any;
  resplike: any;
  topicdata: any;
  topicresplength: any;
  lstpstname: any;
  lstpostedat: any;
  topreplies: any;
  topviews: any;
  topusers: any;
  toplikes: any;
  topicuser1: any;
  topicuser2: any;
  topicusers: any;
  pageNo: any = 1;
  TopicReplyValidation: boolean = false;
  TopicReplyLengthValidation: boolean = false;
  pageTab: any = "categories";
  isLatestBtn: boolean = true;
  catgtype: string = "Latest";
  selcatgid: string;
  showcat: boolean = true;
  catgtopicsdata: any[] = [];
  noTopicRecords: boolean = false;
  noCatTopicRecords: boolean = false;
  ntfnpage: any;
  toggle: boolean = true;
  btnntfn: any;
  ntfndesc: any = "You will be notified if someone mentions your @name or replies to you.";
  ntfnlevel: any;
  userTopics: any[] = [];
  userTotalTopics: any[] = [];
  userResp: any[] = [];
  noTopicsRecords: boolean = false;
  topid: any;
  titleDeta: any;
  submitted1 = false;
  postId: any;
  editTopicCatId: any;
  respId: any;
  resPostId: any;
  usertopicdata: any;
  userLikesReceived: any[] = [];
  seltopicdata: any;
  titleupdateresult: any;
  topicCount: any;
  postCount: any;
  likesReceived: any;
  searchOpp: any;
  searchOpp1: any;
  ispixel: boolean = false;
  extid: string = "";
  pixelscript: any = [];
  routerpixelscripts: any[] = [];
  pixel_type: string = "";
  get f() {
    return this.createtopicForm.controls;
  }
  get e() {
    return this.updatetopicForm.controls;
  }
  get g() {
    return this.updateresForm.controls;
  }
  ngOnInit() {
    this.SeoService.setPageMetaData('Dashboard | The most rewarding business community - Conversant.pro', 'Connect with peers, business opportunities and industry news while earning rewards for participation in the community and business surveys.');
    //this.SeoService.createCanonicalURL();
    // this.catgtype 
    this.getUserData();
    this.getSurveys();
    this.getRewardPoints();
    this.getPostLikeCount();
    this.GetTopOpportunities(0);
    this.getlatesttopics();
    this.getUserRewardSurveysCount();   
    this.createtopicForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(15)]],
      message: ['', [Validators.required, Validators.minLength(20)]],
      category: ['', Validators.required]
    });
    this.updatetopicForm = this.formBuilder.group({
      updatetitle: ['', [Validators.required, Validators.minLength(15)]]
    });
    this.updateresForm = this.formBuilder.group({
      updatemessage: ['', [Validators.required, Validators.minLength(20)]]
    });
    this.reloadtoDash();
    //var data = "https://discourse.conversant.pro/t/advertisers-begin-to-boycott-facebook/207"
    //let data1 = data.split('/');
    this.ntfnpage = localStorage.getItem("ntfnpage");
    if (this.ntfnpage == "headerpage") {
      let id = localStorage.getItem("ntftopid");
      let title = localStorage.getItem("ntftoptitle");
      if (id != undefined || title != undefined) {
        this.getResponses(id, title, 'categories');
        localStorage.setItem("ntfnpage", '');
      }
    }
    this.route.params.subscribe(
      params => {
        if (params['title'] && params['id']) {
          if (params['tab'] == 'c') {
            this.pageTab = "categories";
          }
          else if (params['tab'] == 'l') {
            this.pageTab = "Latest";
          }
          this.getResponses(params['id'], params['title'], this.pageTab);
        }
      });
    this.route.queryParams.subscribe(params => {
      if (typeof params['leadid'] != 'undefined' && params['pc'] != 'undefined') {
        if (params['pc'] == 't450') {
          this.extid = params['extid'];
          //this.loadsimpliScript();
          this.loadpixelScript(params['leadid']);
          this.ispixel = true;
        }
      }
      if (typeof params['leadid'] != 'undefined') {
        this.facebooktrackingScript();
      }
    })
    this.Discusercreatedtopics();
    this.Discuserlikesreceived();
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    // stop here if form is invalid
    if (this.createtopicForm.invalid) {
      this.spinner.hide();
      return;
    }
    let dealrsp: dashOpp = new dashOpp();
    dealrsp.topictitle = this.txtTopicTitle;
    dealrsp.topicbody = this.txtTopicBody;
    dealrsp.catId = this.ddlCatId;
    this.topicsData = [];
    this.service.createnewtopic(dealrsp)
      .subscribe((res: any) => {
        $('#staticBackdrop').modal('hide');
        this.getlatesttopics();
        this.Discusercreatedtopics();
      });
  }
  getUserData() {
    this.service.getUserDetails()
      .subscribe((result: any) => {
        this.firstName = result.firstName;
        this.lastName = result.lastName;
        this.userlogo = result.facebook_user_logo;
        this.facebookid = result.facebookId;
        this.userName = result.userName;
        if (this.userlogo == "" || this.userlogo == null) {
          this.profilepic = "assets/icon-svg/profile.png"
        }
        else if (this.facebookid == null) {
          this.profilepic = "profileuploads/" + this.userlogo;
        }
        else { this.profilepic = this.userlogo; }
      })
      //this.loadpixelScript('8F101862-DE62-437E-BF96-3D167A3A903A'); testing purpose
  };
  getSurveys() {
    this.surservice.GetSurveysList()
      .subscribe((result: any) => {
        if (result) {
          this.noRecords = false;
          this.surveyList = result;
          //this.surveysCount = this.surveyList.length;
        }
        else {
          this.noRecords = true;
          //this.surveysCount = 0;
        }
      });
  }
  getUserRewardSurveysCount() {
    this.surservice.GetUserRewardSurveys()
      .subscribe((result: any) => {
        if (result) {
          this.surveysCount = result;
        }
        else {
          this.surveysCount = 0;
        }
      });
  }
  getRewardPoints() {
    this.surservice.GetRewardPointsList()
      .subscribe((result: any) => {
        if (result) {
          this.reachedpoints = result.reachedPoints;
          this.targetpoints = result.targetPoints;
          this.totalpoints = result.totalPoints;
          this.pendingpoints = result.pendingPoints;
          this.rewardpercentage = result.percentage;
        }
        else {
          this.reachedpoints = 0;
          this.targetpoints = 0;
          this.totalpoints = 0;
          this.pendingpoints = 0;
          this.rewardpercentage = 0;
        }
        $('#progress_bar').css('width', this.rewardpercentage + "%");
        $('#progress_bar1').css('width', this.rewardpercentage + "%");
      });
  }
  GetTopOpportunities(catgid) {
    this.OppList = [];
    this.oppservice.GetTopOpportunities(catgid)
      .subscribe((result: any) => {
        if (result.length > 0) {
          this.noOppRecords = false;
          this.OppList = result;
        }
        else {
          this.noOppRecords = true;
        }
      });   
  }
  getPostLikeCount() {
    this.service.GetPostLikes()
      .subscribe((result: any) => {
        this.postslikes = result;
        this.topicCount = this.postslikes.topic_count;
        this.postCount = this.postslikes.post_count;
        this.likesReceived = this.postslikes.likes_received;
      });
  };
  GetRouterurltrackingpixel(userid,ptid) {
    this.oppservice.getrouterurltrackingpixel(userid, ptid)
      .subscribe((result: any) => {
        this.pixelscript = result;
        this.pixel_type = this.pixelscript.pixel_type;
        if (this.pixel_type == 'J') {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.async = true;// it is true by default, but we are just applying it.
          if (this.pixelscript.src != '') {
            script.src = this.pixelscript.src;
          }
          else if (this.pixelscript.attribute_value != '') {
            script.innerHTML = this.pixelscript.attribute_value
          }
          document.body.appendChild(script);
        }
        else if (this.pixel_type == 'I') {
          var imgsfpixel = document.createElement('img');
          imgsfpixel.height = 1;
          imgsfpixel.width = 1;
          imgsfpixel.alt = "Ipixel";
          if (this.pixelscript.src != '') {
            imgsfpixel.src = this.pixelscript.src;
          }
          else if (this.pixelscript.attributrValue != '') {
            imgsfpixel.innerHTML = this.pixelscript.attributrValue
          }
          document.getElementsByTagName('head')[0].appendChild(imgsfpixel);
        }
      });
  }
  getrouterurlpixels(userid, ptid) {
    this.oppservice.getrouterurlpixels(userid, ptid)
      .subscribe((result: any) => {
        this.routerpixelscripts = result;
        if (this.routerpixelscripts.length > 0) {
          for (let routerpixel of this.routerpixelscripts) {
              this.pixelscript = routerpixel;
              this.pixel_type = this.pixelscript.pixel_type;
              if (this.pixel_type == 'J') {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.async = true;// it is true by default, but we are just applying it.
                if (this.pixelscript.src != '') {
                  script.src = this.pixelscript.src;
                }
                else if (this.pixelscript.attribute_value != '') {
                  script.innerHTML = this.pixelscript.attribute_value
                }
                document.body.appendChild(script);
              }
              else if (this.pixel_type == 'I') {
                var imgsfpixel = document.createElement('img');
                imgsfpixel.height = 1;
                imgsfpixel.width = 1;
                imgsfpixel.alt = "Ipixel";
                if (this.pixelscript.src != '') {
                  imgsfpixel.src = this.pixelscript.src;
                }
                else if (this.pixelscript.attributrValue != '') {
                  imgsfpixel.innerHTML = this.pixelscript.attributrValue
                }
                document.getElementsByTagName('head')[0].appendChild(imgsfpixel);
              }
          }
        }
        //this.routerpixelscripts = result;
        //this.pixel_type = this.pixelscript.pixel_type;
        //if (this.pixel_type == 'J') {
        //  var script = document.createElement("script");
        //  script.type = "text/javascript";
        //  script.async = true;// it is true by default, but we are just applying it.
        //  if (this.pixelscript.src != '') {
        //    script.src = this.pixelscript.src;
        //  }
        //  else if (this.pixelscript.attribute_value != '') {
        //    script.innerHTML = this.pixelscript.attribute_value
        //  }
        //  document.body.appendChild(script);
        //}
        //else if (this.pixel_type == 'I') {
        //  var imgsfpixel = document.createElement('img');
        //  imgsfpixel.height = 1;
        //  imgsfpixel.width = 1;
        //  imgsfpixel.alt = "Ipixel";
        //  if (this.pixelscript.src != '') {
        //    imgsfpixel.src = this.pixelscript.src;
        //  }
        //  else if (this.pixelscript.attributrValue != '') {
        //    imgsfpixel.innerHTML = this.pixelscript.attributrValue
        //  }
        //  document.getElementsByTagName('head')[0].appendChild(imgsfpixel);
        //}
      });
  }
  newwindow(url) {
    //var w = window.open(url, '_blank', 'height=500,width=500');
    var iMyWidth;
    var iMyHeight;
    //half the screen width minus half the new window width (plus 5 pixel borders).
    iMyWidth = (window.screen.width / 2) - (400);
    //half the screen height minus half the new window height (plus title and status bars).
    iMyHeight = (window.screen.height / 2) - (225);
    //Open the window.
    var win2 = window.open(url, "_blank", "status=no,height=450,width=800,resizable=yes,left=" + iMyWidth + ",top=" + iMyHeight + ",screenX=" + iMyWidth + ",screenY=" + iMyHeight + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    win2.focus();
  }
  resizeIframe() {
    var iframe = document.getElementById("iframe1");
    iframe.style.height = (iframe.ownerDocument.body.scrollHeight + 300 + 'px');
  }
  LernMore(opp) {
    let myObj = { oppCatId: opp.opportunityCatgid, oppDesc: opp.opportunityDesc, oppId: opp.opportunityId, oppRespdesc: opp.opportunityRespdesc, oppResId: opp.opportunityRespid, oppTitle: opp.opportunityTitle, oppTotalOppDesc: opp.totalOpportunityDesc, pageMode: "DashBoard" };
    localStorage.setItem("oppObject", JSON.stringify(myObj));
    window.location.href = '/opportunities';
  }
  getCatgTopics(catgid, catgname) {
    this.catgtopicsdata = [];
    this.selcatgid = catgid;
    this.catgtype = catgname;
    this.service.getcategorytopics(catgid)
      .subscribe((result: any) => {
        this.catgtopicsdata = result.topic_list.topics;
        this.catgtopicsdata.forEach(e => {
          this.categData.forEach(opt => {
            if (e.category_id == opt.id) {
              e.category_name = opt.name;
              e.background_colour = '#' + opt.color;
              //e.text_color = '#' + opt.text_color;
            }
          })
        });
      });
    window.scroll(0, 500);
    this.showcat = false;
  }
  getlatesttopics() {
    if (this.selcatgid && this.catgtype != "Latest") {
      this.getCatgTopics(this.selcatgid, this.catgtype);
      this.getCategories();
    }
    else {
      this.getLatTopics();
    }
  }
  getLatTopics() {
    this.selcatgid;
    this.catgtype;
    this.spinner.show();
    this.service.getlatesttopics()
      .subscribe((result: any) => {
        if (result.topic_list) {
          this.topicsData = result.topic_list.topics;
          if (this.topicsData.length == 0) {
            this.noTopicRecords = true;
          } else {
            this.noTopicRecords = false;
          }
          this.catgtopicsdata = result.topic_list.topics;
          if (this.catgtopicsdata.length == 0) {
            this.noCatTopicRecords = true;
          } else {
            this.noCatTopicRecords = false;
          }
        }
        this.getCategories();
      });

    this.showcat = true;
  }
  getCategories() {
    this.service.getcategorieslist()
      .subscribe((res: any) => {
        this.categData = res;
        this.topicsData.forEach(e => {
          this.categData.forEach(opt => {
            if (e.category_id == opt.id) {
              e.category_name = opt.name;
              e.background_colour = '#' + opt.color;
              e.text_color = '#' + opt.text_color;
            }
          })
        });
        if (this.pageTab == "Latest") {
          this.highlightedLatestTab();
        }
        else if (this.pageTab == "UserTopics") {
          this.highlightedMyTopicsTab();
        }
        else if (this.pageTab == "Likes") {
          this.highlightedLikesTab();
        }
        else {
          this.highlightedCatgTab();
        }
        this.spinner.hide();
        this.spinner.hide();
        this.spinner.hide();

      });
  }
  backtoallTopics() {
    this.showcat = true;
    this.catgtype = "Latest";
    this.getLatTopics();
  }
  getResponses(id, title, pagename) {
    this.pageTab = pagename;
    this.topicId = id;
    this.isTopics = false;
    this.topicDetails = '';
    this.topicTitle = '';
    this.titlecatgname = '';
    this.catgcolor = '';
    this.topicResponce = [];
    //this.spinner.show();
    this.service.getsingletopicpostsbyid(id)
      .subscribe((res: any) => {
        this.topicdata = res;
        this.seltopicdata = res;
        this.topreplies = res.posts_count - 1;
        this.topviews = res.views;
        this.topusers = res.participant_count;
        this.toplikes = res.like_count;
        this.topicusers = res.details.participants;
        //this.topicuser1 = res.details.participants[0].username;
        //this.topicuser2 = res.details.participants[1].username;
        this.topicDetails = res.post_stream.posts.filter(e => e.post_number == 1)[0];
        this.topicTitle = res.title;
        this.titlecatgname = res.category_name;
        this.catgcolor = "#" + res.category_color;
        this.topiclike = res.post_stream.posts[0].actions_summary[0].count;
        this.topicResponce = res.post_stream.posts.filter(e => e.post_number != 1);
        this.topicresplength = this.topicResponce.length;
        this.lstpstname = res.details.last_poster.username;
        this.lstpostedat = res.last_posted_at;
        //this.resplike = res.post_stream.posts.filter(e => e.post_number != 1)[0].actions_summary[0].count;
        this.suggestedtopics = res.suggested_topics;
        this.service.getsingletopicpostsuserbyid(id)
          .subscribe((result: any) => {
            this.usertopicdata = result;
            if (this.usertopicdata.details.notification_level == 1) {
              this.btnntfn = "Follow";
              this.toggle = true;
            } else {
              this.btnntfn = "Following";
              this.toggle = false;
            }
          })

        this.spinner.hide();
        this.spinner.hide();
        window.scroll(0, 500)
        this.location.go('dashboard/t/' + res.slug + '/' + id);
        // this.spinner.hide();
      });
  }
  bactoTopic() {
    // this.catgtype = "Latest";
    this.topicId = '';
    this.topicDetails = '';
    this.topicTitle = '';
    this.titlecatgname = '';
    this.catgcolor = '';
    this.topicResponce = [];
    this.txtReplyDesc = '';
    this.TopicReplyValidation = false;
    this.TopicReplyLengthValidation = false;
    //this.topicsData = [];
    this.categData = [];
    this.isTopics = true;
    this.isLatestBtn = true;
    this.pageNo = 1;
    this.getlatesttopics();
    this.Discusercreatedtopics();
    this.location.go('dashboard');
  }
  onTopicReply() {
    this.spinner.show();
    this.TopicReplyValidation = false;
    this.TopicReplyLengthValidation = false;
    if (!this.txtReplyDesc) {
      this.TopicReplyValidation = true;
      this.spinner.hide();
    }
    else {
      if (this.txtReplyDesc.length > 20) {
        this.sendreply();
      }
      else {
        this.TopicReplyLengthValidation = true;
        this.spinner.hide();
      }
    }
  }

  sendreply() {
    let dealrsp: dashOpp = new dashOpp();
    dealrsp.topicId = this.topicId;
    dealrsp.txtReplyDesc = this.txtReplyDesc;
    this.service.topicreply(dealrsp)
      .subscribe((res: any) => {
        this.txtReplyDesc = '';
        this.getResponses(this.topicId, this.topicTitle, this.pageTab);
      });
  }

  //CreatePost() {
  //  let dealrsp: dashOpp = new dashOpp();
  //  dealrsp.topictitle = this.txtTopicTitle;
  //  dealrsp.topicbody = this.txtTopicBody;
  //  dealrsp.catId = this.ddlCatId;
  //  this.topicsData = [];
  //  this.service.createnewtopic(dealrsp)
  //    .subscribe((res: any) => {
  //      $('#staticBackdrop').modal('hide');
  //      this.getlatesttopics();
  //    });

  //}

  createnewtopic() {
    this.txtTopicTitle = '';
    this.txtTopicBody = '';
    this.ddlCatId = '';
  }


  MoreLatest() {
    this.highlightedLatestTab();
    window.scroll(0, 500);
  }
  brouseCatg() {
    this.isTopics = true;
    this.highlightedCatgTab()
    window.scroll(0, 500);
  }


  postlikes(id) {
    this.spinner.show();
    this.service.likepost(id).subscribe((res: any) => {
      if (res == true) {
        // this.topiclike = this.topiclike + 1;
        this.getResponses(this.topicId, this.topicTitle, this.pageTab);
      }
      else {
        this.spinner.hide();
      }
    });

  }
  highlightedCatgTab() {
    $('#pills-home-tab').addClass('active');
    $('#pills-home-tab').attr('aria-selected', true);
    $('#pills-home').addClass('active');
    $('#pills-home').addClass('show');
    $('#pills-profile-tab').removeClass('active');
    $('#pills-profile-tab').attr('aria-selected', false);
    $('#pills-profile').removeClass('active');
    $('#pills-profile').removeClass('show');
    $('#pills-my-profile-tab').removeClass('active');
    $('#pills-my-profile-tab').attr('aria-selected', false);
    $('#pills-my-profile').removeClass('active');
    $('#pills-my-profile').removeClass('show');
    $('#pills-my-favorite-tab').removeClass('active');
    $('#pills-my-favorite-tab').attr('aria-selected', false);
    $('#pills-my-favorite').removeClass('active');
    $('#pills-my-favorite').removeClass('show');
  }
  highlightedLatestTab() {
    $('#pills-profile-tab').addClass('active');
    $('#pills-profile-tab').attr('aria-selected', true);
    $('#pills-profile').addClass('active');
    $('#pills-profile').addClass('show');
    $('#pills-home-tab').removeClass('active');
    $('#pills-home-tab').attr('aria-selected', false);
    $('#pills-home').removeClass('active');
    $('#pills-home').removeClass('show');
    $('#pills-my-profile-tab').removeClass('active');
    $('#pills-my-profile-tab').attr('aria-selected', false);
    $('#pills-my-profile').removeClass('active');
    $('#pills-my-profile').removeClass('show');
    $('#pills-my-favorite-tab').removeClass('active');
    $('#pills-my-favorite-tab').attr('aria-selected', false);
    $('#pills-my-favorite').removeClass('active');
    $('#pills-my-favorite').removeClass('show');
  }
  highlightedMyTopicsTab() {
    $('#pills-my-profile-tab').addClass('active');
    $('#pills-my-profile-tab').attr('aria-selected', true);
    $('#pills-my-profile').addClass('active');
    $('#pills-my-profile').addClass('show');
    $('#pills-profile-tab').removeClass('active');
    $('#pills-profile-tab').attr('aria-selected', false);
    $('#pills-profile').removeClass('active');
    $('#pills-profile').removeClass('show');
    $('#pills-home-tab').removeClass('active');
    $('#pills-home-tab').attr('aria-selected', false);
    $('#pills-home').removeClass('active');
    $('#pills-home').removeClass('show');
    $('#pills-my-favorite-tab').removeClass('active');
    $('#pills-my-favorite-tab').attr('aria-selected', false);
    $('#pills-my-favorite').removeClass('active');
    $('#pills-my-favorite').removeClass('show');
  }
  highlightedLikesTab() {
    $('#pills-my-favorite-tab').addClass('active');
    $('#pills-my-favorite-tab').attr('aria-selected', true);
    $('#pills-my-favorite').addClass('active');
    $('#pills-my-favorite').addClass('show');
    $('#pills-home-tab').removeClass('active');
    $('#pills-home-tab').attr('aria-selected', false);
    $('#pills-home').removeClass('active');
    $('#pills-home').removeClass('show');
    $('#pills-profile-tab').removeClass('active');
    $('#pills-profile-tab').attr('aria-selected', false);
    $('#pills-profile').removeClass('active');
    $('#pills-profile').removeClass('show');
    $('#pills-my-profile-tab').removeClass('active');
    $('#pills-my-profile-tab').attr('aria-selected', false);
    $('#pills-my-profile').removeClass('active');
    $('#pills-my-profile').removeClass('show');

  }
  LatestMoreClick() {
    this.spinner.show();
    this.service.getlatestpagetopics(this.pageNo)
      .subscribe((result: any) => {
        result.topic_list.topics.forEach(e => {
          this.topicsData.push(e);
        })

        this.topicsData.forEach(e => {
          this.categData.forEach(opt => {
            if (e.category_id == opt.id) {
              e.category_name = opt.name;
              e.background_colour = '#' + opt.color;
              e.text_color = '#' + opt.text_color;
            }
          })
        });
        if (result.topic_list.topics.length < 30) {
          this.isLatestBtn = false;
          this.spinner.hide();
        }
        else {
          this.isLatestBtn = true;
          this.pageNo = this.pageNo + 1;
          this.spinner.hide();
        }
      });
  }
  reloadtoDash() {
    this.service.sessionExist().subscribe((data) => {
      var token = this.cookieService.get('token');
      if (token && data == "t") {
      }
      else if (token && data == "at") {
        window.location.href = '/dashboard';
      }
      else {
        window.location.href = '/login';
      }
    });
  }
  Discusercreatedtopics() {
    this.userTopics = [];
    this.oppservice.GetDiscUserTopics()
      .subscribe((result: any) => {
        if (result.user_actions) {
          this.userTotalTopics = result.user_actions;
          this.userTopics = result.user_actions.filter(e => e.post_number == 1);
          if (this.userTopics.length == 0) {
            this.noTopicsRecords = true;
          }
          else {
            this.noTopicsRecords = false;
          }
        }
      });
  }
  Discuserlikesreceived() {
    this.userLikesReceived = [];
    this.oppservice.GetDiscUserActions(2)
      .subscribe((result: any) => {
        if (result.user_actions) {
          this.userLikesReceived = result.user_actions;
        }
      });
  }
  titleClick(res) {
    this.userResp = [];
    this.userResp = this.userTotalTopics.filter(e => e.topic_id == res.topic_id);
  }
  btnDiscdeltopic(topic_id) {
    this.topid = topic_id;
    $('#delTopicModel').modal('show');
  }
  Discdeltopic() {
    $('#delTopicModel').modal('hide');
    this.oppservice.DelDiscTopics(this.topid)
      .subscribe((result: any) => {
        // alert("Topic deleted successfully.")
        this.Discusercreatedtopics();
      });
  }
  btnDisEdit(ut) {
    //this.titleDeta = ut;
    this.updatetopicForm.controls['updatetitle'].setValue(this.seltopicdata.title);
    // this.updatetopicForm.controls['updatemessage'].setValue(ut.excerpt);
    this.service.getsingletopicpostsbyid(this.seltopicdata.id).subscribe((result: any) => {
      this.postId = result.post_stream.posts[0].id
    });

    //if (ut.post_id == null) {
    //  this.service.getsingletopicpostsbyid(this.seltopicdata.topic_id).subscribe((result: any) => {
    //    this.postId = result.post_stream.posts[0].id
    //  });
    //}
    //else {
    //  this.postId = ut.topic_id;
    //}
  }
  onUpdateTitle() {
    if (this.updatetopicForm.invalid) {
      this.submitted1 = true;
      return;
    }
    else {
      this.UpdateTitleDetails();
    }
  }
  UpdateTitleDetails() {
    let dealrsp: dashOpp = new dashOpp();
    dealrsp.topicId = this.seltopicdata.id;// this.titleDeta.topic_id;
    dealrsp.topictitle = this.txtTopicTitle;
    dealrsp.catId = this.seltopicdata.category_id;//this.titleDeta.category_id;
    this.oppservice.updateTopicTitle(dealrsp).subscribe((result: any) => {
      $('#updatetitleModal').modal('hide');
      this.titleupdateresult = result;
      if (result == true) {
        this.topicTitle = this.txtTopicTitle;
        this.seltopicdata.title = this.txtTopicTitle;
      }
      //this.Discusercreatedtopics();
      //this.bactoTopic();

    });
  }

  clickEvent(event) {
    //if you just want to toggle the class; change toggle variable.
    this.toggle = !this.toggle;
  }
  chngentfn(id) {
    if (this.toggle) {
      this.btnntfn = "Follow";
      //this.ntfndesc = "You will be notified if someone mentions your @name or replies to you.";
      this.ntfnlevel = "1";
      this.service.notificationlevel(id, this.ntfnlevel).subscribe((res: any) => {

      });
    } else {
      this.btnntfn = "Following";
      //this.ntfndesc = "You will receive notifications because you created this topic."
      this.ntfnlevel = "3";
      this.service.notificationlevel(id, this.ntfnlevel).subscribe((res: any) => {

      });
    }
  }
  btnResEdit(res) {
    this.respId = res.id;
    this.resPostId = res.topic_id;
    this.updateresForm.controls['updatemessage'].setValue(res.raw);
    this.txtTopicBody = res.raw;
  }
  onUpdateRes() {
    if (this.updateresForm.invalid) {
      this.submitted3 = true;
      return;
    }
    else {
      this.UpdateResDetails();
    }
  }
  UpdateResDetails() {
    let dealrsp: dashOpp = new dashOpp();
    dealrsp.topicId = this.respId;
    dealrsp.topicbody = this.txtTopicBody;
    this.oppservice.updateTopicRes(dealrsp).subscribe((result: any) => {
      $('#updateResModal').modal('hide');
      this.getResponses(this.resPostId, "", "UserTopics");

    });
  }
  topicsClick() {
    this.highlightedMyTopicsTab();
    window.scroll(0, 500);
  }
  likesClick() {
    this.highlightedLikesTab();
    window.scroll(0, 500);
  }
  public loadpixelScript(leadid) {
    //this.GetRouterurltrackingpixel(leadid, 1);
    this.getrouterurlpixels(leadid, 1);
  }
  public facebooktrackingScript() {
    var imgfbtrack = document.createElement('img');
    imgfbtrack.height = 1;
    imgfbtrack.width = 1;
    imgfbtrack.alt = "";
    imgfbtrack.src = "https://www.facebook.com/tr?id=124147289922780&ev=PageView&noscript=1";
    document.getElementsByTagName('head')[0].appendChild(imgfbtrack);
  }
  public loadsimpliScript() {
    //let node = document.createElement('script');
    //node.src = url;
    //node.type = 'text/javascript';
    //node.async = true;
    //node.charset = 'utf-8';
    //document.getElementsByTagName('head')[0].appendChild(node);
    var imgsfpixel = document.createElement('img');
    imgsfpixel.height = 1;
    imgsfpixel.width = 1;
    imgsfpixel.alt = "";
    imgsfpixel.src = "https://tag.simpli.fi/sifitag/83eb0110-3d7a-0139-3bb9-06abc14c0bc6?tx_val=5&tx_id=" + this.extid;
    document.getElementsByTagName('head')[0].appendChild(imgsfpixel);
  }
}
export class dashOpp {
  topictitle: string;
  topicbody: string;
  catId: number;
  topicId: number;
  txtReplyDesc: string;
}
