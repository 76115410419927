import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { Subscription } from 'rxjs/Subscription';
import { NgxSpinnerService } from "ngx-spinner";
import { invitefriendsService } from './../common/services/invitefriends.service'
import { seoservice } from './../common/services/seo.service';
declare var $: any;
@Component({
  selector: 'app-invitefriends',
  templateUrl: './invitefriends.component.html'
})
export class InviteFriendsComponent implements OnInit {

  constructor(private service: invitefriendsService, private router: Router, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, private SeoService: seoservice) { }
  filterParams: any[];
  friendsList: any;
  campaignId: any;
  tempSubject: any;
  isSuccess: boolean = false;
  noinvitedfriends: boolean = false;
  emailslist: any;
  isrecord: boolean = false;
  resultmsg: any;
  showmsg: boolean = false;

  ngOnInit() {
    this.SeoService.setPageMetaData('Refer A Friend | Earn rewards together - Conversant.pro', 'Invite a friend or colleague to join and you can both earn rewards as you share opinions, solve challenges, and grow your business.');
    this.campaignId = 1912;
    this.tempSubject = "Important Message from";
    this.resultmsg = "Please enter first name and email address";
    this.filterParams = [
      {
        FriendFirstName: '',
        FriendEmailAddress: ''
      },
      {
        FriendFirstName: '',
        FriendEmailAddress: ''
      },
      {
        FriendFirstName: '',
        FriendEmailAddress: ''
      },
      {
        FriendFirstName: '',
        FriendEmailAddress: ''
      },
      {
        FriendFirstName: '',
        FriendEmailAddress: ''
      }
    ];
    this.GetFriendInformation();
  }
  GetFriendInformation() {
    this.service.GetFriendInformation()
      .subscribe((result: any) => {
        this.friendsList = result;
        if (this.friendsList.length == 0) {
          this.noinvitedfriends = true;
        }
      });
  }
  InviteFriends() {
    this.validateEmail();
    //this.SubmitFriends();
  }
  validateEmail() {
    this.emailslist = "";
    this.isrecord = false;
    this.isSuccess = false;
    this.resultmsg = "Please enter first name and email address";
    for (let i = 0; i < 5; i++) {
      if (this.filterParams[i].FriendFirstName != 'undefined' && this.filterParams[i].FriendFirstName != "" && this.filterParams[i].FriendEmailAddress != 'undefined' && this.filterParams[i].FriendEmailAddress !="") {
        this.emailslist = this.emailslist + this.filterParams[i].FriendEmailAddress + ",";
        this.isrecord = true;
      }
    }
    if (this.isrecord) {
      this.service.validEmail(this.emailslist)
        .subscribe((result: any) => {
          this.resultmsg = result;
          //this.emailAddressCheck();
          if (this.resultmsg == "accepted") {
            this.showmsg = false;
            this.SubmitFriends();
          }
          else {
            this.showmsg = true;
          }
        });
    }
    else {
      this.showmsg = true;
    }
  }
  emailAddressCheck() {
    this.service.emailAddressCheck(this.filterParams[0].FriendEmailAddress)
      .subscribe((result: any) => {
        if (result.CpaCount >= 1) {
        }
        else if (result.CpaCount == 0) {
          this.SubmitFriends();
        }
      });
  }
  SubmitFriends() {
    this.service.Invitefriends(this.filterParams,this.campaignId,this.tempSubject)
      .subscribe((result: any) => {
        this.isSuccess = true;
      });
  }
  valuechange($event) {
    var reqchars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz ";
    var pos = reqchars.indexOf($event.key);
    var isValid = true;
    if (pos == -1) {
      isValid = false;
    }
    return isValid;
  }
}
export class InviteFriends {
  FriendFirstName: string;
  FriendEmailAddress: string;
}
